import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next"; // Import i18next
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { b2cSettingsText } from "WebApiClient";
interface Language {
  code: string;
  lang: string;
}
interface language {
  setLanguage: (lng: string) => void;
}
const languages: Language[] = [
  { code: "de", lang: "Deutsch" },
  { code: "en", lang: "English" },
  { code: "es", lang: "Español" },
  { code: "fr", lang: "Français" },
  { code: "ga", lang: "Gaeilge" },
  { code: "gr", lang: "Ελληνικά" },
  { code: "hu", lang: "Magyar" },
  { code: "it", lang: "Italiano" },
  { code: "nl", lang: "Nederlands" },
  { code: "no", lang: "Norsk" },
  { code: "pl", lang: "Polski" },
  { code: "pt", lang: "Português" },
  { code: "se", lang: "Svensk" },
  { code: "tk", lang: "Türkçe" },
];
type DynamicLanguage = {
  [key: string]: {
    Checked: boolean;
  };
};
const LanguageSelector: React.FC<language> = (props) => {
  const B2CSettings = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dynamicLanguage: DynamicLanguage = (() => {
    try {
      const settings = JSON.parse(B2CSettings || "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Right\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":true},\"Business\":{\"Checked\":true,\"preSelect\":true},\"First\":{\"Checked\":true,\"preSelect\":true}},\"Style\":\"\",\"ConfirmationText\":\"bllal bllass #filekey# blaas sadsad\",\"AllowedLanguage\":{\"EN\":{\"Checked\":true},\"FR\":{\"Checked\":true},\"DE\":{\"Checked\":true}},\"showPassengerTitleName\":\"Off\"}");
      if (settings.AllowedLanguage) {
        return settings.AllowedLanguage;
      } else {
        return {
          DE: { Checked: true },
          EN: { Checked: true },
          ES: { Checked: true },
          FR: { Checked: true },
          GA: { Checked: true },
          GR: { Checked: true },
          HU: { Checked: true },
          IT: { Checked: true },
          NL: { Checked: true },
          NO: { Checked: true },
          PL: { Checked: true },
          PT: { Checked: true },
          SE: { Checked: true },
          TK: { Checked: true },
        };
      }
    } catch {
      return {
        DE: { Checked: true },
        EN: { Checked: true },
        ES: { Checked: true },
        FR: { Checked: true },
        GA: { Checked: true },
        GR: { Checked: true },
        HU: { Checked: true },
        IT: { Checked: true },
        NL: { Checked: true },
        NO: { Checked: true },
        PL: { Checked: true },
        PT: { Checked: true },
        SE: { Checked: true },
        TK: { Checked: true },
      };;
    }
  })();
  let result = languages.filter(language => {
    let code = language.code.toUpperCase();
    return dynamicLanguage[code] && dynamicLanguage[code].Checked;
  });
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language || languages[0]?.code
  );
  const sessionInfo = useSelector((state: State) => state.Session);
  const showLanguagePreferred: b2cSettingsText = (() => {
    try {
      return JSON.parse(sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };
  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
    setSelectedLanguage(lng);
    props.setLanguage(lng);
  };
  useEffect(() => {
    if (showLanguagePreferred?.ShowLanguageDecimalTabB2C == "German") {
      changeLanguage("de");
    } else {
      changeLanguage(result[0]?.code)
    }
  }, []);

  return (
    <>
      {
        result.length > 1 ?
          <Dropdown
            className=""
            isOpen={isOpen}
            toggle={toggleDropdown}
            defaultValue="en">
            <DropdownToggle caret>
              {selectedLanguage.toUpperCase()}
            </DropdownToggle>
            <DropdownMenu className="">
              {result.map((lng) => (
                <DropdownItem key={lng.code} onClick={() => changeLanguage(lng.code)}>
                  {lng.lang}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown> : null
      }
    </>
  );
};
export default LanguageSelector;