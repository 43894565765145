import FlightDetails from "components/FareSearch/component/FlightDetails/FlightDetails";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { ValidationErrorMessage } from "components/Shared/ErrorMessage";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import { useQuery } from "hooks/various";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import { State } from "rootExports/rootReducer";
import {
  Session_Reducer_HideCustomerWaitScreen,
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_PushTransaction,
  Session_Reducer_SetWatiScreenInfo,
  Session_Reducer_ShowCustomerWaitScreen,
  Session_Reducer_ShowGeneralWaitScreen,
} from "rootExports/SessionReducer";
import {
  AgentFrontendFareSearchOptions,
  AvailableFaresSearchRequest,
  CustomerProfileData,
  FareSearchSubmitApiResponse,
} from "WebApiClient";
import { GetInitialInput } from "../Business/GetInitialInput";
import { ValidateSearch } from "../Business/ValidateSearch";
import { IFareSearchInputState } from "../types/FareSearchTypes";
import { GetRequestData } from "./ParseInput";

interface FareSearchProps {
  IsModifySearch: boolean;
  OnSearchSubmitted: (response: FareSearchSubmitApiResponse) => void;
  LastSubmitted: AvailableFaresSearchRequest | undefined;
  OnReset: () => void;
  Config: AgentFrontendFareSearchOptions;
  Init: IFareSearchInputState;
  submitResult: FareSearchSubmitApiResponse | undefined;
  GoToResults?: () => void;
  boxRef?: any;
  setScrollingDown?: any;
  language: string;
  isNext: boolean;
  scrollingDown?: boolean;
  isFareListHeader?: boolean;
  isB2cAirline: boolean;
};
interface FareSearchShellProps {
  IsModifySearch: boolean;
  OnSearchSubmitted: (response: FareSearchSubmitApiResponse) => void;
  LastSubmitted: AvailableFaresSearchRequest | undefined;
  submitResult: FareSearchSubmitApiResponse | undefined;
  GoToResults?: () => void;
  OnReset: () => void;
  boxRef?: any;
  setScrollingDown?: any;
  language: string;
  isNext: boolean;
  scrollingDown?: boolean;
  isFareListHeader?: boolean;
  isB2cAirline: boolean;
};
const FareSearchShell: React.FC<FareSearchShellProps> = (props) => {
  const [errorOccured, setErrorOccured] = useState(false);
  const [fetchingConfig, setFetchingConfig] = useState(false);
  const [configuration, setConfiguration] = useState<
    AgentFrontendFareSearchOptions | undefined
  >(undefined);
  const [initInput, setInitInput] = useState<IFareSearchInputState | undefined>(
    undefined
  );
  const availFareClient = useAvailableFaresClient();
  const submitResult = props?.submitResult;
  const customerProfileData: CustomerProfileData | undefined = useSelector(
    (state: State) => state.Session.FrontendSettings.customerProfileData?.data
  );
  const query = useQuery();

  useEffect(() => {
    SetInitialInput();
    return () => {
      sendPageHeight();
    };
  }, []);

  const sendPageHeight = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  function SetInitialInput() {
    setFetchingConfig(true);
    availFareClient
      .getFareSearchConfiguration()
      .then((response) => {
        setConfiguration(response);
        const initialInput: IFareSearchInputState = GetInitialInput(
          response,
          props?.LastSubmitted,
          customerProfileData,
          query
        );

        setInitInput(initialInput);
        setErrorOccured(false);
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetchingConfig(false);
      });
  }

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 82 && e.ctrlKey) {
      e.preventDefault();
      SetInitialInput();
    }
  }

  return (
    <React.Fragment>
      {fetchingConfig ? (
        <div className="">
          <WaitingCard />
        </div>
      ) : (
        <React.Fragment>
          {initInput && configuration && (
            <div onKeyDown={OnKeyDown}>
              <FareSearch
                GoToResults={props?.GoToResults}
                IsModifySearch={props?.IsModifySearch}
                LastSubmitted={props?.LastSubmitted}
                OnSearchSubmitted={props?.OnSearchSubmitted}
                OnReset={SetInitialInput}
                Config={configuration}
                Init={initInput}
                submitResult={submitResult}
                boxRef={props.boxRef}
                setScrollingDown={props.setScrollingDown}
                language={props.language}
                isNext={props.isNext}
                scrollingDown={props.scrollingDown}
                isFareListHeader={props.isFareListHeader}
                isB2cAirline={props.isB2cAirline}
              />
            </div>
          )}
          {errorOccured && (
            <div className="row">
              <div className="col-12">
                <ErrorDisplay data={undefined} />
              </div>
              <div className="col-12">
                <div className="d-grid">
                  <button className="btn btn-primary" onClick={SetInitialInput}>
                    Retry
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* <Fab /> */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const FareSearch: React.FC<FareSearchProps> = (props) => {
  const DEBUG = false;
  const availFareClient = useAvailableFaresClient();
  const dispatch = useDispatch();

  // const [submitResult, setSubmitResult] = useState<FareSearchSubmitApiResponse | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const configuration = props?.Config;
  const [isRuleHide, setIsRuleHide] = useState<boolean>(false);
  const [input, setInput] = useState<IFareSearchInputState>(props?.Init);
  const [keyBoardModalOpen, setKeyboardModalOpen] = useState(false);
  const submitResult = props?.submitResult;

  function ToggleKeyBoardModal() {
    setKeyboardModalOpen(!keyBoardModalOpen);
  }
  const isValidSearch = ValidateSearch(input, configuration);
  function OnUpdate(state: IFareSearchInputState): void {
    setInput(state);
  }

  const sendPageHeight1 = () => {
    const height = 800;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };
  const sendPageHeight2 = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  function OnSubmit() {
    if (isValidSearch) {
      if (DEBUG) {
        console.log("Submitting search...");
      }
      const requestData: AvailableFaresSearchRequest = GetRequestData(input);
      dispatch(Session_Reducer_ShowCustomerWaitScreen());
      dispatch(Session_Reducer_ShowGeneralWaitScreen());
      dispatch(
        Session_Reducer_SetWatiScreenInfo({
          Label: "Searching fares",
          Percentage: 25,
        })
      );
      sendPageHeight1();
      availFareClient
        .searchAvailableFares(requestData)
        .then((response) => {
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (DEBUG) {
            console.log("Faresearch response received!");
          }
          props?.OnSearchSubmitted(response);
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          dispatch(Session_Reducer_HideCustomerWaitScreen());
          dispatch(Session_Reducer_HideGeneralWaitScreen());
          sendPageHeight2();
        });
    } else {
      toast.error("Could not submit query. There are Invalid fields.");
    }
  }

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 13) {
      OnSubmit();
    }
  }

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  let fieldContainer: string[];

  if (settingArr[7]) {
    try {
      fieldContainer = JSON.parse(settingArr[7])
        .Container.split("#")
        .filter((item: any) => item !== "");
    } catch (error) {
      // Provide a default value or error indicator
      fieldContainer = [];
    }
  } else {
    // Provide a default value or error indicator
    fieldContainer = [];
  }

  useEffect(() => {
    try {
      // const isBranch = JSON.parse( settingArr[7] !== undefined ? settingArr[7] : "");
      const isRuleHide = fieldContainer.includes("HIDERULES");
      setIsRuleHide(isRuleHide);
    } catch (error) {
      setIsRuleHide(false); // Handle parsing error
    }
  }, [fieldContainer]);

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}
        className="p-3 screen-query PaddingScrollForm"
        onKeyDown={OnKeyDown}>
        <div className="widthScrollForm">
          {(errorOccured ||
            props?.submitResult?.responseMetaData?.errorOccured === true) && (
              <ErrorDisplay data={submitResult?.responseMetaData} />
            )}
          {submitResult?.result && (
            <React.Fragment>
              <ValidationErrorMessage
                error={!submitResult.result.validationResult.isValid}
                messages={submitResult.result.validationResult.errorSummary}
              />
            </React.Fragment>
          )}
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Row>
                  <FlightDetails
                    UpdateState={OnUpdate}
                    IsModifySearch={false}
                    Input={input}
                    OnSubmit={OnSubmit}
                    isValidSearch={isValidSearch}
                    IsAvailableFares={input!.AvailableFares}
                    Configuration={configuration}
                    setInput={setInput!}
                    State={input}
                    Update={OnUpdate}
                    boxRef={props.boxRef}
                    setScrollingDown={props.setScrollingDown}
                    language={props.language}
                    scrollingDown={props.scrollingDown}
                    isFareListHeader={props.isFareListHeader}
                    isB2cAirline={props.isB2cAirline}
                  />
                </Row>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>

    </React.Fragment>
  );
};
export default FareSearchShell;