import { faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgentApiURL, SeatmapUrl } from "Constants";
import { useBookingClient } from "hooks/useHttpClient";
import IframeResizer from 'iframe-resizer-react';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { State } from "rootExports/rootReducer";
import { BookingDetailsApiResponse, BookingItemModel } from "WebApiClient";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
interface BookingMangerSeatmapModalProps {
  Booking: BookingItemModel,
  Toggle: () => void
  IsOpen: boolean
}
const BookingMangerSeatmapModal: React.FC<BookingMangerSeatmapModalProps> = props => {
  const token = useSelector((state: State) => state.Session.JwtToken);
  const { filekey, sourceCode, cartId, bookingId, platingCarrier, webAgent } = props.Booking.latestVersion.generalInfo;
  const { gds, receivedFrom, terminalCountry, terminalPCC, user } = props.Booking.latestVersion.generalInfo.crs!;
  const { currencyCode } = props.Booking.latestVersion.generalInfo.totalPrice!;
  const iframeRef = useRef<any>(null);
  const [iframeKey, setiFrameKEy] = useState<number>(Math.random())
  const onResized = (data: any) => { }
  const onMessage = (data: any) => { }

  return (
    <React.Fragment>
      <Modal size="lg" style={{ minWidth: 1400, maxWidth: 1400 }} centered toggle={props.Toggle} isOpen={props.IsOpen}>
        <ModalHeader toggle={props.Toggle}>Seatmap</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-2">
              <Alert color="info">
                <p>Use the seat selection below to assign seats to passengers. To confirm your selection, use the "Booking" button.</p>
              </Alert>
            </div>
            <div className="col-12">
              <IframeResizer key={iframeKey} onMessage={onMessage} onResized={onResized} scrolling={true} checkOrigin={false} style={{ width: '1px', minWidth: '100%', minHeight: 200 }} heightCalculationMethod="max" forwardRef={iframeRef} src={`${SeatmapUrl}/agentplus?filekeys=${filekey}&currency=${currencyCode}&pcc=${terminalPCC}&gds=${gds}&received_from=${encodeURIComponent(receivedFrom)}&crsuser=${encodeURIComponent(user)}&terminal_country=${terminalCountry}&source=${sourceCode}&language=EN&server=${AgentApiURL}&bookingid=${bookingId}&cartid=${cartId}&token=${token}&carrier=${platingCarrier.code}&webagent=${webAgent}`}></IframeResizer>

            </div>
          </div>

          {/* <div className="container">
            <div className="row">
              <div className="col-12 mb">
                <a target="_blank" className="btn btn-primary" href={`https://test.seatmap.hitchhiker.net/agentplus?filekeys=${filekey}&currency=${currencyCode}&pcc=${terminalPCC}&gds=${gds}&received_from=${encodeURIComponent(receivedFrom)}&terminal_country=${terminalCountry}&source=${sourceCode}&language=EN&server=${AgentApiURL}&token=${token}`}>Open external Seatmap</a>

              </div>
              <div className="col-12 mb-2">

                <div className="" style={{ overflow: "auto", border: "1px solid #000", width: "1400", height: "800" }}>

                </div>
              </div>
            </div>
          </div> */}

        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => { setiFrameKEy(Math.random()) }}  ><FontAwesomeIcon icon={faSync} /> refresh</Button>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );
}

export default BookingMangerSeatmapModal



