import CustomerWaitScreen from "components/General/WaitScreens/CustomerWaitScreen";
import GeneralWaitScreen from "components/General/WaitScreens/GeneralWaitScreen";
import { GetDeployInfo } from "hooks/GetAppInfo";
import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_SetDeployInfo } from "rootExports/SessionReducer";
import { AppRoutes } from "routes/index";
import { Environment } from "./Constants";
import { useLocation } from "react-router-dom";
import { b2cSettingsText } from "WebApiClient";


interface DynamicStyleComponentProps {
  theme: 'light' | 'dark';
}

const App: React.FC<{}> = ({ }) => {
  const session = useSelector((state: State) => state.Session);

  const B2cDynamicCss = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);

  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(B2cDynamicCss || "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Left\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":true},\"Business\":{\"Checked\":true,\"preSelect\":true},\"First\":{\"Checked\":true,\"preSelect\":true}},\"showMarqueeFrom\":\"2024-05-20T11:54\",\"showMarqueeText\":\"\",\"showMarqueeTo\":\"2024-05-20T11:55\",\"Style\":\"\",\"priceOption\":\"pricePerPerson\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();


  const createAndInjectStylesheet = (cssText: string): HTMLStyleElement => {
    const dynamicStyleElement: HTMLStyleElement = document.createElement('style');
    dynamicStyleElement.appendChild(document.createTextNode(cssText));
    document.head.appendChild(dynamicStyleElement);
    return dynamicStyleElement;
  };

  useEffect(() => {
    const cssText = B2CSettings?.Style;
    if (cssText !== undefined) {
      const dynamicStyleElement = createAndInjectStylesheet(cssText);
      return () => {
        if (dynamicStyleElement) {
          document.head.removeChild(dynamicStyleElement);
        }
      };
    }
  }, [B2cDynamicCss]);

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
    }

    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  const location = useLocation();
  const { pathname } = location;
  const isFareSearchForm = pathname.includes("fareSearchForm");
  let settingArr = otherSettings.split("\n");
  var sideBarSetting = settingArr[0].split("/");
  var siteBarPosition = sideBarSetting[0];
  var siteBarColors: any =
    sideBarSetting[1] == undefined
      ? { textColor: "#ffffff", bgColor: "#232323" }
      : JSON.parse(sideBarSetting[1]);
  const dispatch = useDispatch();

  useEffect(() => {
    GetDeployInfo().then((info) => {
      dispatch(Session_Reducer_SetDeployInfo(info));
      console.log(
        `Environment ${info.DeployedEnvironment} and Build configuration ${Environment} detected. AgentPlus is ready.`
      );
    });
  }, [siteBarColors?.textColor, siteBarColors?.bgColor, siteBarPosition]);

  useEffect(() => {
    return () => {
      sendPageHeight();
    };
  }, []);

  const sendPageHeight = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  return (
    <React.Fragment>
      <GeneralWaitScreen></GeneralWaitScreen>
      <CustomerWaitScreen></CustomerWaitScreen>
      <div className={ inIframe() ? "overflow loginBCImageWithoutImg":"overflow loginBCImage"}>
        {/* <div className={`vh-100`}> */}
        <div>
          <div
            style={{
              minHeight: "100vh",
              backgroundImage: isFareSearchForm ? 'url("")' : "",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* <VersionDifferenceModal /> */}
            <AppRoutes />
          </div>
        </div>
      </div>
      <ToastContainer position="top-center"/>
    </React.Fragment>
  );
};

export default App;
