import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCheckCircle,
  faExclamationTriangle,
  faLuggageCart,
  faPlaneArrival,
  faPlaneDeparture,
  faShoppingCart,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import { PriceDisplay } from "components/Shared/PriceDisplay";
import React, { useEffect, useState } from "react";
import { Airline, AvailableFareConnectionModel, AvailableFareModel, FareHeaderView, b2cSettingsText } from "WebApiClient";
import { ServiceAddObj } from "./AncillariesModal";
import { CabinClasses } from "./Fare.Header.CabinClasses";
import FareHeaderIncludedServices from "./Fare.Header.IncludedServices";
import { GetTotalFarePrice, GetTotalFarePrice1 } from "./FarePriceOverview";
import FareLeg from "./Fare.Leg";
import { FareFilterApplicability } from "./FareFilter.Applicability";
import FareLegConnectionTable from "./Fare.Leg.ConTbl";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import Passengers from "components/FareSearch/component/Passengers/Passengers";
import { State } from "rootExports/rootReducer";
import { AvailableFaresFare } from "components/AvailableFares/component/AvailableFares.Fares";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";

interface HeaderFareProps {
  OriginalFareIndex: number;
  Data: FareHeaderView;
  PlatingCarriers: Airline[];
  ToggleFare: () => void;
  IsExpanded: boolean;
  TotalPrice: number;
  TotalTax: number;
  Currency: string;
  Services: ServiceAddObj[];
  myRefs?: any;
  FareIndex?: any;
  FareData: AvailableFareModel;
  internalSelectedConnections: number[];
  GetSelectedConnectionIndexByLegIndex: any;
  ToggleFlightTimes: () => void;
  DistinctPlatingCarriers: Airline[];
  ShowFlightTimes: boolean;
  SetSelectedConnection: any;
  Applicability?: FareFilterApplicability;
  BookFare: () => void;
  IsFareBooking: boolean;
  setExtraAncCheck?: (newValue: boolean) => void;
  OnRefresh?: any;
  showSelected: boolean;
  TotalFare?: AvailableFaresFare[];
  upsellBook: any;
  SelectedConnections: any;
}

const FareHeader: React.FC<HeaderFareProps> = (props) => {
  const { t } = useTranslation();
  const platingCarriersAmount: number = props.PlatingCarriers.length;
  const showPlatingCarriers: boolean = platingCarriersAmount < 2 || !props.IsExpanded;

  const allServices = props.Services.flat();
  const servicesAmount = allServices.length;
  const session = useSelector((state: any) => state.Segment);
  const b2cSettingsText = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);
  const b2cSettingsText2 = useSelector((state: State) => state.Session.B2cSettings);

  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(b2cSettingsText || "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Left\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":true},\"Business\":{\"Checked\":true,\"preSelect\":true},\"First\":{\"Checked\":true,\"preSelect\":true}},\"showMarqueeFrom\":\"2024-05-20T11:54\",\"showMarqueeText\":\"\",\"showMarqueeTo\":\"2024-05-20T11:55\",\"Style\":\"\",\"priceOption\":\"pricePerPerson\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();

  const Segment = session.value.Segments;
  const legs = props.FareData.legs;
  const matchesMediaQuery = useMediaQuery("(max-width:1200px)");
  const matchesMediaQuery2 = useMediaQuery("(max-width:1300px)");
  var salesPreferenceDisplayNameCode: string | undefined = "";
  var salesPreferenceDisplayNameHtml: string | undefined = "";
  //modal state for sales
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  function ToggleExpiredModal() {
    setExpiredModalOpen(!expiredModalOpen);
  }
  interface Airport {
    code: string;
    cityName: string;
    countryCode: string;
    countryName: string;
    airportName: string;
  }

  function splitContent(str: string | undefined) {
    // Split the string at the "|" character
    if (str != undefined) {
      if (!str.includes('|')) {
        salesPreferenceDisplayNameCode = str;
      }
    }
    if (str != undefined) {
      if (str.includes('|')) {
        let [leftContent, rightContent] = str.split('|');
        salesPreferenceDisplayNameHtml = leftContent;
        salesPreferenceDisplayNameCode = rightContent;
      }
    }
  }

  const getSegments = (): Array<Airport[]> => {
    const Segments = new Array<Airport[]>();
    const Arrival: Airport[] = [];
    const Departure: Airport[] = [];
    legs.forEach((leg, liId) => {
      splitContent(leg?.fareTypeInformation?.salesPreferenceDisplayName);
      leg.connections.forEach((connection, cId) => {
        connection.segments.forEach((segment, sId) => {
          const arrivalAirport: Airport = {
            code:
              segment.arrivalAirport.iata == ""
                ? segment.arrivalAirport.iata
                : segment.arrivalAirport.iata,
            cityName: segment.arrivalAirport.cityName,
            countryCode: segment.arrivalAirport.countryCode,
            countryName: segment.arrivalAirport.countryName,
            airportName: segment.arrivalAirport.name
          };
          const departureAirport: Airport = {
            code:
              segment.departureAirport.iata == ""
                ? segment.departureAirport.iata
                : segment.departureAirport.iata,
            cityName: segment.departureAirport.cityName,
            countryCode: segment.departureAirport.countryCode,
            countryName: segment.departureAirport.countryName,
            airportName: segment.departureAirport.name
          };

          if (!Arrival.some((a) => isEqual(a, arrivalAirport))) {
            Arrival.push(arrivalAirport);
          }
          if (!Departure.some((d) => isEqual(d, departureAirport))) {
            Departure.push(departureAirport);
          }
        });
      });
    });
    Segments.push(Arrival);
    Segments.push(Departure);
    return Segments;
  };

  // Function to check if two airports are equal
  const isEqual = (a: Airport, b: Airport): boolean => {
    return (
      a.code === b.code &&
      a.cityName === b.cityName &&
      a.countryCode === b.countryCode
    );
  };

  const mergeAndRemoveDuplicates = (arrays: Airport[][]): Airport[] => {
    const mergedArray: Airport[] = [];
    const mergedSet = new Set<string>();

    arrays.forEach((arr) => {
      arr.forEach((airport) => {
        const airportString = JSON.stringify(airport);
        if (!mergedSet.has(airportString)) {
          mergedArray.push(airport);
          mergedSet.add(airportString);
        }
      });
    });
    return mergedArray;
  };

  const allSegMentArray = mergeAndRemoveDuplicates(getSegments());
  const allSegMentArrayModified = _.map(allSegMentArray, obj => {
    return {
      ...obj,
      countryName: _.startCase(_.toLower(obj.countryName))
    };
  });
  //check selected connection
  const checkFareTypeBool: boolean = props.FareData.legs[0].connections[0].segments[0].fareType == "Published";

  const sendPageHeight = () => {
    setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", height + "px"], "*");
    }, 0);
  };

  //upsell b2c calculation

  const [upscaleFilterData, setUpscaleFilterData] = useState<AvailableFaresFare[]>([])
  const [upSaleToggle, setupSaleToggle] = useState(false)
  const [searchFlightNumber, setSearchFlightNumber] = useState<string[][]>([])

  const UpscaleCalculation = (upscalePropertyArray: any, propertyString: string) => {
    const filterObjs = upscalePropertyArray;

    // Extract flight numbers grouped by leg number as a nested array
    const groupedFlightNumbers: any = Object.values(
      filterObjs.reduce((acc: any, filterObj: any) => {
        filterObj.codeNumber.forEach((codeEntry: any) => {
          const { legNumber, flightNumber } = codeEntry;

          if (!acc[legNumber]) {
            acc[legNumber] = [];
          }
          acc[legNumber].push(flightNumber);
        });
        return acc;
      }, {})
    );

    setSearchFlightNumber(groupedFlightNumbers);

    const newArr = props.TotalFare;
    const filteredData: any = newArr?.filter(item =>
      item.Fare.legs.length === filterObjs.length &&
      item.Fare.fareIndex !== filterObjs[0].fareIndex &&

      Object.entries(groupedFlightNumbers).every(([legIndex, flightNumbers]: [any, any]) => {
        const leg = item.Fare.legs[Number(legIndex)];

        // if (!leg) return false;

        // Check if all flight numbers for the leg are present in segments
        return flightNumbers.every((flightNumber: any) =>
          leg.connections.some(connection =>
            connection.segments.some(segment => segment.flightNumber === flightNumber)
          )
        );
      })
    );

    if (filteredData?.length === 0) {
      toast.error("No upsell found", { autoClose: 1500 });
      if (propertyString == 'connectionTriggered') {
        setupSaleToggle(true);
      } else {
        setupSaleToggle(false);
      }
    } else {
      if (!props.ShowFlightTimes) {
        props.ToggleFlightTimes();
      }
      if (propertyString == 'connectionTriggered') {
        setupSaleToggle(true);
      } else {
        setupSaleToggle(prev => !prev);
      }
    }
    // filtering if relevant con is not find
    const newFArr: any = filteredData?.filter((singleUpscaleFare: any) =>
      singleUpscaleFare?.Fare?.legs.some((leg: any) => {
        const con: AvailableFareConnectionModel = _.find(leg.connections, {
          index: findConnectionIndices(singleUpscaleFare.Fare, groupedFlightNumbers)[leg.index],
        });
        return con !== undefined;
      })
    );

    setUpscaleFilterData(newFArr);
  };

  //////////////////////////////////////////////////////// UPSELL //////////////////////////////////////////////////

  //carousel upsell responsive
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //calculating connection index for selected upsell
  const findConnectionIndices = (fare: AvailableFareModel, flightNumbers: string[][]) => {
    return flightNumbers.map(legFlightNumbers => {
      // Initialize to -1; if a matching connection is found, we'll update it
      let foundIndex: any = -1;

      // Iterate over each leg to look for a matching connection
      for (const leg of fare.legs) {
        for (const connection of leg.connections) {
          // Check if this connection contains all flight numbers in the current group
          const allMatch = legFlightNumbers.every(flightNumber =>
            connection.segments.some(segment => segment.flightNumber === flightNumber)
          );

          // If a full match is found, set the connectionIndex and break out of the loop
          if (allMatch) {
            foundIndex = connection?.connectionIdentifier?.connectionIndex;
            break; // Stop further checking within this leg if a match is found
          }
        }

        if (foundIndex !== -1) break; // Stop further legs if a match was found
      }

      // Return the connectionIndex if found, or -1 if no full match was found for this group
      return foundIndex;
    });
  };


  //upsell booking
  function BookFareUpsell(upSellFare: { Fare: AvailableFareModel, Applicability: any }) {
    const newConnectionIndex: any = findConnectionIndices(upSellFare.Fare, searchFlightNumber);
    if (!props.IsFareBooking && newConnectionIndex != undefined) {
      const payload: any = {
        Fare: upSellFare.Fare,
        SelectedConnections: newConnectionIndex,
        AddedServices: props.Services,
        OnSelectConnection: props.SetSelectedConnection,
      };
      props.upsellBook(payload);
    }
  }

  //property checking calculation
  function Upscale(stringPass: string) {
    const upscalePropertyArray: any = [];
    props.FareData.legs.map((leg: any, indexLeg: number) => {
      const flightNumberArr: any = [];
      const indexToSearch: any = matchConnIndex(leg, props.internalSelectedConnections![indexLeg])
      leg.connections[indexToSearch].segments.map((flightNum: any, segIndex: number) => {
        flightNumberArr.push({ flightNumber: flightNum.flightNumber, legNumber: indexLeg });
      })
      upscalePropertyArray.push({
        code: leg?.platingCarrier?.code,
        arrivalDisplayTime: leg.connections[props.internalSelectedConnections[indexLeg]].connectionHeader?.arrivalDisplayTime,
        departureDisplayTime: leg.connections[props.internalSelectedConnections[indexLeg]].connectionHeader?.departureDisplayTime,
        codeNumber: flightNumberArr,
        fareIndex: props.FareData.fareIndex
      })
    })

    UpscaleCalculation(upscalePropertyArray, stringPass);
  }

  function matchConnIndex(connectionArr: any, targetIndex: number) {
    for (let i = 0; i < connectionArr?.connections.length; i++) {
      const connection = connectionArr?.connections[i];

      // If the connection's index field matches the targetIndex, return the connection's position
      if (connection.index === targetIndex) {
        return i; // Return the position of this connection in the connections array
      }
    }
  }


  const hasBrandNameInLegs = (data: AvailableFareModel) => {
    return data.legs.some(leg =>
      leg.connections.some(connection =>
        connection.segments.some(segment =>
          segment.hasOwnProperty('brandName') && segment.brandName !== null && segment.brandName !== ""
        )
      )
    );
  };

  function GetSelectedConnectionIndexByLegIndexUpsell(faredata: any, legIndex: number): number {
    if (props.SelectedConnections === undefined) {
      return findConnectionIndices(faredata.Fare, searchFlightNumber)[legIndex];
    } else {
      return props.SelectedConnections[legIndex];
    }
  }

  useEffect(() => {
    if (upSaleToggle) {
      Upscale('connectionTriggered');
    }
  }, [props.internalSelectedConnections])

  return (
    <div
      className="row bb-1 fareRow fareHeaderContainer"
      onClick={() => {
        props.ToggleFare();
        props.OnRefresh();
        props.ToggleFlightTimes();
        sendPageHeight();
      }}
    >
      {props.myRefs?.current ? (
        <>
          <div
            ref={(el) => (props.myRefs.current[props.FareIndex] = el)}
            className="col-xs-12 col-md-2 logoHeaderImg "
            onClick={() => {
              props.ToggleFlightTimes();
              sendPageHeight();
            }}
            key={"ASdasda"}
          >
            <div className="fairDetails">
              {props.PlatingCarriers.map((airline, index) => (
                <div className="d-flex" key={"asdasda" + index}>
                  <AirlineLogo
                    IsAlliance={false}
                    className="col-2 fare__carrier_logo align-self-center"
                    PlatingCarrier={airline.code!}
                    PlatingCarrierName={airline.hint!}
                    key={`fd_f${props.OriginalFareIndex}_airHeader_${index}`}
                  />
                  {/* <span>{airline.hint!}</span>{" "} */}
                </div>
              ))}
            </div>
            {servicesAmount > 0 && (
              <div className="border">
                + {servicesAmount.toString()} <FontAwesomeIcon icon={faStar} />{" "}
                <span className="float-end"></span>
              </div>
            )}
          </div>
          {matchesMediaQuery && (
            <div
              className="col-xs-12 col-md-2 d-flex justify-content-end align-items-center text-end priceHeaderImg"
              onClick={() => {
                props.ToggleFlightTimes();
                sendPageHeight();
              }}
            >
              <div className="w-100 PriceDisplay">
                <div className="specialFW">
                  {!checkFareTypeBool && (
                    <span onClick={(e) => {
                      e.stopPropagation();
                      salesPreferenceDisplayNameHtml?.length! > 0 && ToggleExpiredModal();
                    }}>
                      {/* {t("FareList.FareHeader.SpecialFare")} */}
                      <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                    </span>
                  )}
                  {
                    salesPreferenceDisplayNameHtml?.length > 0 && <Modal className="confirmationError" centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal}>
                      <ModalHeader toggle={ToggleExpiredModal}>
                        <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                      </ModalHeader>
                      <ModalBody>
                        <div dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameHtml }} />
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={() => {
                          ToggleExpiredModal()
                        }} color="primary">
                          Ok
                        </Button>
                      </ModalFooter>
                    </Modal>
                  }
                  <div
                    className=""
                    style={{
                      display: "block",
                      minWidth: "100px",
                      lineHeight: "18px"
                    }}
                  >
                    <strong className="price">
                      <PriceDisplay
                        price={B2CSettings.priceOption == "pricePerPerson" ? GetTotalFarePrice1(props.TotalPrice, props.Services, props.FareData.bookingPassengers.length) : GetTotalFarePrice(props.TotalPrice, props.Services)}
                        currency={props.Currency}
                      />
                    </strong>
                    <span
                      style={{
                        fontWeight: "800",
                        color: "#DC3545",
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      {B2CSettings.priceOption == "pricePerPerson" ? t("FareList.FareHeader.PricePerPerson") : t("FareList.FareHeader.TotalPrice")}
                    </span>
                  </div>
                </div>


              </div>
              <div className="paddingFarePrice w-100 bookCabinTextLeft">
                <span className="cabinClasss text-white px-2">
                  <CabinClasses
                    CabinClasses={props.Data.cabinClassCombined!}
                    BrandName={props.FareData.legs!}
                  />
                </span>
                <FareHeaderIncludedServices
                  Data={props.Data}
                  Fareindex={props.OriginalFareIndex}
                  BaggageType={props.FareData.freeBaggageAllowance}
                  FareTypeInfo={props.FareData.legs[0].fareTypeInformation}
                  mealCode={props?.FareData?.mealCode}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center col-xs-12 col-md-2 text-center flex-column 0.0.0 fareBookingImgPriceContainer">
          {props.PlatingCarriers.map((airline, index) => (
            <>
              <div
                className="py-2 d-flex justify-content-center align-items-center"
                key={"asdasda" + index}
                onClick={() => {
                  props.ToggleFlightTimes();
                  sendPageHeight();
                }}
              >
                <div>
                  <AirlineLogo
                    IsAlliance={false}
                    className="col-2 fare__carrier_logo align-self-center"
                    PlatingCarrier={airline.code!}
                    PlatingCarrierName={airline.hint!}
                    key={`fd_f${props.OriginalFareIndex}_airHeader_${index}`}
                  />
                  {/* <span>{airline.hint!}</span>{" "} */}
                </div>
              </div>
            </>
          ))}
          {matchesMediaQuery && (
            <div
              className="col-xs-12 col-md-2 d-flex justify-content-end align-items-center text-end priceHeaderImg"
              onClick={() => {
                props.ToggleFlightTimes();
                sendPageHeight();
              }}
            >
              <div className="w-100">
                <div className="specialFW">
                  {!checkFareTypeBool && (
                    <span onClick={(e) => {
                      e.stopPropagation();
                      salesPreferenceDisplayNameHtml?.length! > 0 && ToggleExpiredModal();
                    }}>
                      {/* {t("FareList.FareHeader.SpecialFare")} */}
                      <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                    </span>
                  )}
                  {
                    salesPreferenceDisplayNameHtml?.length > 0 && <Modal className="confirmationError" centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal}>
                      <ModalHeader toggle={ToggleExpiredModal}>
                        <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                      </ModalHeader>
                      <ModalBody>
                        <div dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameHtml }} />
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={() => {
                          ToggleExpiredModal()
                        }} color="primary">
                          Ok
                        </Button>
                      </ModalFooter>
                    </Modal>
                  }
                  <div
                    className=""
                    style={{
                      display: "block",
                      minWidth: "100px",
                      lineHeight: "18px"
                    }}
                  >
                    <strong className="price d-block">
                      <PriceDisplay
                        price={B2CSettings.priceOption == "pricePerPerson" ? GetTotalFarePrice1(props.TotalPrice, props.Services, props.FareData.bookingPassengers.length) : GetTotalFarePrice(props.TotalPrice, props.Services)}
                        currency={props.Currency}
                      />
                    </strong>
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: "800",
                        color: "#DC3545",
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      {B2CSettings.priceOption == "pricePerPerson" ? t("FareList.FareHeader.PricePerPerson") : t("FareList.FareHeader.TotalPrice")}
                    </span>

                  </div>
                </div>


              </div>
              <div className="paddingFarePrice w-100 bookCabinTextLeft">
                <span className="cabinClasss text-white px-2">
                  <CabinClasses
                    CabinClasses={props.Data.cabinClassCombined!}
                    BrandName={props.FareData.legs!}
                  />
                </span>
                <FareHeaderIncludedServices
                  Data={props.Data}
                  Fareindex={props.OriginalFareIndex}
                  BaggageType={props.FareData.freeBaggageAllowance}
                  FareTypeInfo={props.FareData.legs[0].fareTypeInformation}
                  mealCode={props?.FareData?.mealCode}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className="col-xs-12 col-md-12 col-lg-7 col-xl-7 text-center fareHeaderBook"
        onClick={() => {
          props.ToggleFlightTimes();
          sendPageHeight();
        }}
      >
        {props.FareData.legs.map(
          (leg, index) =>
            props.internalSelectedConnections[index] !== undefined && (
              <FareLeg
                LegIndex={leg.index}
                SelectedConnection={props.GetSelectedConnectionIndexByLegIndex(
                  leg.index
                )}
                GetSelectedConnectionIndexByLegIndex={
                  props.GetSelectedConnectionIndexByLegIndex!
                }
                Data={leg}
                CityName={Segment[index]}
                isActivate={true}
                key={"FareLeg" + index}
                ShowFlightTimes={props.ShowFlightTimes}
                SelectConnection={props.SetSelectedConnection}
                ShowLegPlatingCarrier={props.DistinctPlatingCarriers.length > 1}
                ApplicableConnections={
                  props.Applicability &&
                    props.Applicability.ApplicableConnections
                    ? props.Applicability.ApplicableConnections[leg.index]
                    : undefined
                }
                Fare={props.FareData}
                ToggleFlightTimes={props.ToggleFlightTimes}
              />

            )
        )}
      </div>

      {!matchesMediaQuery && (
        <div
          className="col-xs-12 col-md-2 d-flex justify-content-end align-items-center text-end priceHeaderImg"
          onClick={() => {
            props.ToggleFlightTimes();
            sendPageHeight();
          }}
        >
          <div className="w-100">
            <div className="specialFW">

              <div
                className=""
                style={{
                  display: "block",
                  minWidth: "100px",
                  lineHeight: "18px"
                  // marginTop: "-4px",
                  // marginBottom: "-12px",
                }}
              >

                {!checkFareTypeBool && (
                  <span onClick={(e) => {
                    e.stopPropagation();
                    salesPreferenceDisplayNameHtml?.length! > 0 && ToggleExpiredModal();
                  }}>
                    {/* {t("FareList.FareHeader.SpecialFare")} */}
                    <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                  </span>
                )}
                {
                  salesPreferenceDisplayNameHtml?.length > 0 && <Modal className="confirmationError" centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal}>
                    <ModalHeader toggle={ToggleExpiredModal}>
                      <span dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameCode }} />
                    </ModalHeader>
                    <ModalBody>
                      <div dangerouslySetInnerHTML={{ __html: salesPreferenceDisplayNameHtml }} />
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={() => {
                        ToggleExpiredModal()
                      }} color="primary">
                        Ok
                      </Button>
                    </ModalFooter>
                  </Modal>
                }
                <strong className="price d-block">
                  <PriceDisplay
                    price={B2CSettings.priceOption == "pricePerPerson" ? GetTotalFarePrice1(props.TotalPrice, props.Services, props.FareData.bookingPassengers.length) : GetTotalFarePrice(props.TotalPrice, props.Services)}
                    currency={props.Currency}
                  />
                </strong>
                <span
                  style={{
                    fontWeight: "800",
                    color: "#DC3545",
                    fontSize: "12px",
                    display: "block",
                  }}
                >
                  {/* {t("FareList.FareHeader.TotalPrice")} */}
                  {(B2CSettings.priceOption == "pricePerPerson" && (props.FareData?.calculationResult?.calculationsByPassengerType![0]?.passengerAmount > 1 || props.FareData?.calculationResult?.calculationsByPassengerType?.length! > 1)) ? t("FareList.FareHeader.PricePerPerson") : t("FareList.FareHeader.TotalPrice")}
                </span>
              </div>
            </div>

          </div>
          <div className="paddingFarePrice w-100 bookCabinTextLeft">
            <span className="cabinClasss text-white px-2">
              <CabinClasses
                CabinClasses={props.Data.cabinClassCombined!}
                BrandName={props.FareData.legs!}
              />
            </span>
            <FareHeaderIncludedServices
              Data={props.Data}
              Fareindex={props.OriginalFareIndex}
              BaggageType={props.FareData.freeBaggageAllowance}
              FareTypeInfo={props.FareData.legs[0].fareTypeInformation}
              mealCode={props?.FareData?.mealCode}
            />
          </div>
        </div>
      )}

      {props.ShowFlightTimes && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="tableWidth">
            {props.FareData.legs.map(
              (leg, index) =>
                props.internalSelectedConnections[index] !== undefined && (
                  <div key={"asdfef" + index}>
                    <div
                      className={`${props.ShowFlightTimes} ? "tableWidth" : "" mt-1 mb-1`}
                    >
                      {props.ShowFlightTimes && (
                        <FareLegConnectionTable
                          radio={
                            props.FareData.legs.length > 1
                              ? props.FareData.legs.some(
                                (leg) => leg.connections.length > 1
                              )
                              : true
                          }
                          key={"FareLegConnectionTable" + leg.index}
                          Data={leg.connections}
                          SelectedConnection={props.GetSelectedConnectionIndexByLegIndex(
                            leg.index
                          )}
                          GetSelectedConnectionIndexByLegIndex={
                            props.GetSelectedConnectionIndexByLegIndex
                          }
                          LegIndex={leg.index}
                          SelectConnection={props.SetSelectedConnection}
                          IsWebFare={leg.isWeb}
                          ApplicableConnections={
                            props.Applicability &&
                              props.Applicability.ApplicableConnections
                              ? props.Applicability.ApplicableConnections[
                              leg.index
                              ]
                              : undefined
                          }
                          Fare={props.FareData}
                          showSelected={props.showSelected}
                          upsellToVisible={false}
                        />
                      )}
                    </div>
                  </div>
                )
            )}
            <div
              className="d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="mb-3 d-flex" style={{ gap: "6px" }}>
                {allSegMentArrayModified
                  .sort((a, b) => a.code.localeCompare(b.code))
                  .map((seg, i) => (
                    <span className="segCodeCity">
                      <span className="segCityName">{seg.code}</span> =
                      {seg.cityName != "" || seg.countryName ? (
                        <>
                          {" "}
                          <span>{seg.airportName},</span>
                          {" "}
                          <span>
                            {seg.cityName} - {seg.countryName}
                          </span>
                          {allSegMentArray.length - 1 !== i && (
                            <>&nbsp; &#183;</>
                          )}
                        </>
                      ) : (
                        <>{"  "}Unknown</>
                      )}
                    </span>
                  ))}
              </div>
            </div>
            {matchesMediaQuery2 && (
              <div className="mb-3">
                {!props.IsFareBooking && props.ShowFlightTimes ? (
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="btn btn-primary text-uppercase ms-1"
                      onClick={() => {
                        props.BookFare();
                        if (props.setExtraAncCheck) {
                          props.setExtraAncCheck(true);
                        }
                        // sendPageHeight1();
                      }}
                    >
                      {t("FareList.FareHeader.Book")}{" "}
                    </button>
                  </div>
                ) : null}
              </div>
            )}
            <div>
              {
                upSaleToggle && upscaleFilterData?.length ? (
                  <div className="upSellCardParent" onClick={(e) => e.stopPropagation()}>
                    <Carousel responsive={responsive}
                      slidesToSlide={5}
                      swipeable={true}
                      className="carouselUpsell">
                      {upscaleFilterData.map((singleUpscaleFare: any, fareIndex: number) =>
                        singleUpscaleFare?.Fare?.legs.map((leg: any, index: number) =>
                          props.internalSelectedConnections[index] !== undefined && (
                            <div
                              className="upSellContainer"
                              key={`upscaleFare_${fareIndex}_leg_${index}`}
                              onClick={(e) => { BookFareUpsell(singleUpscaleFare) }}
                            >
                            
                              <div className="upSellCard" >
                                {props.ShowFlightTimes && (
                                  <FareLegConnectionTable
                                    radio={
                                      props.FareData.legs.length > 1
                                        ? props.FareData.legs.some((leg) => leg.connections.length > 1)  : true
                                    }
                                    key={`FareLegConnectionTable_${index}`}
                                    Data={leg.connections}
                                    SelectedConnection={GetSelectedConnectionIndexByLegIndexUpsell(
                                      singleUpscaleFare,
                                      leg.index
                                    )}
                                    GetSelectedConnectionIndexByLegIndex={
                                      props.GetSelectedConnectionIndexByLegIndex
                                    }
                                    LegIndex={leg.index}
                                    SelectConnection={props.SetSelectedConnection}
                                    IsWebFare={leg.isWeb}
                                    ApplicableConnections={
                                      props.Applicability?.ApplicableConnections
                                        ? props.Applicability.ApplicableConnections[leg.index]
                                        : undefined
                                    }
                                    Fare={singleUpscaleFare?.Fare}
                                    showSelected={props.showSelected}
                                    upsellToVisible={true}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        )
                      )}
                    </Carousel>
                  </div>
                ) : null
              }

            </div>
          </div>
          {!matchesMediaQuery2 && (
            <div>
              {!props.IsFareBooking && props.ShowFlightTimes ? (
                <>
                  {
                    // hasBrandNameInLegs(props.FareData)
                    !props.IsFareBooking &&
                    <div className="mx-3 mb-2">
                      <button
                        className="btn btn-primary text-uppercase ms-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          Upscale('connectionTriggeredOutside');
                        }}
                      >
                        upsell
                      </button>
                    </div>
                  }
                  <div className="mx-3">
                    <button
                      className="btn btn-primary text-uppercase ms-1"
                      onClick={() => {
                        props.BookFare();

                        if (props.setExtraAncCheck) {
                          props.setExtraAncCheck(true);
                        }

                        // sendPageHeight1();
                      }}
                    >
                      {t("FareList.FareHeader.Book")}{" "}
                    </button>
                  </div>

                </>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FareHeader;
