import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { AvailableFareModel, FareAPISRequirements, FareBookingPassengerView } from "WebApiClient";
import { BookingPassenger } from "./FareBooking.Passengers.Passenger";
import { BookingValidResult } from "./FareBooking.Validate";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";

import _ from 'lodash';
interface OwnProps {
  Passengers: FareBookingPassengerView[];
  Apisrequirements: FareAPISRequirements;
  OnPaymentDetails: (data: any) => void;
  ValidationResult: BookingValidResult;
  OnPassengerUpdate: (index: number, data: FareBookingPassengerView) => void;
  Icon: JSX.Element;
  setPaymentBOFDetails: any;
  paymentBOFDetails: any;
  setPaymentDataBackend: any;
  paymentValid: any;
  Fare?: AvailableFareModel
  setIsConfirmEmail?: any
  dontContact: boolean
  reviewedChecked: boolean
}
const FareBookingPassengers: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const [allPassengerTabsOpen, setAllPassengerTabsOpen] = useState(false);
  const [passengerTabsOpen, setPassengerTabsOpen] = useState(
    GetDefaultPassengerTabsOpenState(props.Passengers)
  );
  //collapse
  const [collapseOpen, setCollapseOpen] = useState(false);

  function ToggleCollapse() {
    setCollapseOpen(!collapseOpen);
  }
  function TogglePassengerTab(index: number) {
    let _passengerTabs = [...passengerTabsOpen];
    _passengerTabs[index] = !_passengerTabs[index];
    setPassengerTabsOpen(_passengerTabs);
  }
  function SelectNextPassengerTab(index: number) {
    let _passengerTabs = [...passengerTabsOpen];
    _passengerTabs[index] = false;
    if (_passengerTabs[index + 1] !== null) {
      _passengerTabs[index + 1] = true;
    }
    setPassengerTabsOpen(_passengerTabs);
  }

  useEffect(() => {
    ToggleAllPassengerTabs();
  }, []);

  function ToggleAllPassengerTabs() {
    const invertedSetAll = !allPassengerTabsOpen;
    let newOpenState: boolean[] = [];
    passengerTabsOpen.forEach(() => {
      newOpenState.push(invertedSetAll);
    });

    setAllPassengerTabsOpen(invertedSetAll);
    setPassengerTabsOpen(newOpenState);
  }

  function HasNextPassenger(index: number): boolean {
    if (passengerTabsOpen[index + 1] !== null) return true;
    else return false;
  }

  function GetDefaultPassengerTabsOpenState(
    passengers: FareBookingPassengerView[] | undefined
  ): boolean[] {
    let result: boolean[] = [];
    if (passengers) {
      passengers.forEach((_passenger, index) => {
        if (index === 0) {
          result.push(true);
        } else {
          result.push(false);
        }
      });
    }
    return result;
  }

  const isAdultPresent: any = _.some(props.Passengers, { 'isAdult': true });

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 customerDetails">
          <div className="card">

            {/* <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 16px",
              }}
              onClick={ToggleCollapse}
            >
              <span>
                {props.Icon} {t("FareBooking.PassengerPage.CustomerDetails")}{" "}
              </span>

              <FontAwesomeIcon
                icon={collapseOpen ? faChevronUp : faChevronDown}
              />
            </div>
            <hr /> */}
            <Collapse isOpen={true}>
              <div className="cards-body card-body-primary">
                {props.Passengers && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-12">
                        {props.Passengers.map((passenger, index) => (
                          <BookingPassenger
                            setPaymentBOFDetails={props.setPaymentBOFDetails}
                            paymentBOFDetails={props.paymentBOFDetails}
                            Passenger={passenger}
                            key={`booking_p_${index}`}
                            OnUpdatePassenger={props.OnPassengerUpdate}
                            // OnUseAdressForAll={props.OnUseAdressForAll}
                            Index={index}
                            Apisrequirements={props.Apisrequirements}
                            // CanInputAdress={PassengerCanInputAdress(index, props.Fare.bookingPassengers!)}
                            PassengersAmount={props.Passengers.length}
                            Valid={props.ValidationResult.Passengers[index]}
                            IsOpen={passengerTabsOpen[index]}
                            Toggle={TogglePassengerTab}
                            HasNext={HasNextPassenger(index)}
                            ExpandNext={SelectNextPassengerTab}
                            setPaymentDataBackend={props.setPaymentDataBackend}
                            paymentValid={props.paymentValid}
                            setIsConfirmEmail={props.setIsConfirmEmail}
                            isAdultPresent={isAdultPresent}
                            departureDate={props?.Fare?.legs[0]?.connections[0]?.connectionHeader?.departureDate}
                            dontContact={props.dontContact}
                            reviewedChecked={props.reviewedChecked}
                          />
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FareBookingPassengers;
