import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fare from "components/Fare/Fare";
import { FareFilterApplicability } from "components/Fare/FareFilter.Applicability";
import ErrorBoundary from "components/Shared/ErrorBoundary";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { AvailableFareModel } from "WebApiClient";
import { useTranslation } from "react-i18next";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";
interface OwnProps {
  TotalFaresAmount: number;
  Fares: AvailableFaresFare[];
  airlineCollector: any[];
  ShowAllLegsActive: boolean;
  ShowAllFlightTimesActive: boolean;
  ShowAllCalculationActive: boolean;
  FaresWhichPassfilter: number;
  ValidUntil: Date;
  BookFare: (payload: SetBookingPayload) => void;
  ShowCalcDebugger: boolean;
  myRefs?: any;
  setFetching?: any;
  setError?: any;
  setResponse?: any;
  setExtraAncCheck?: (newValue: boolean) => void;
}

const AvailalableFaresFares: React.FC<OwnProps> = (props) => {
  // const sendPageHeight = () => {
  //   const height = document.body.scrollHeight;
  //   console.log("fare----",height);
  //   window.parent.postMessage(["setHeight", height + "px"], "*");
  // };

  // useEffect(() => {
  //   sendPageHeight();
  // }, []);
  function BookFare(payload: SetBookingPayload) {
    props.BookFare(payload);
  }
  const { t } = useTranslation();
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  function ToggleExpiredModal() {
    setExpiredModalOpen(!expiredModalOpen);
  }

  function RenderFares(
    fares: AvailableFaresFare[],
    allLegsActive: boolean,
    flightTimesActive: boolean,
    allCalc: boolean
  ): JSX.Element {
    return (
      <React.Fragment>
        {fares.map((fare, index) => (
          <ErrorBoundary key={"frs" + index}>
            <Fare
              FareData={fare?.Fare}
              ShowAllLegsActive={allLegsActive}
              ShowAllFlightTimesActive={flightTimesActive}
              ShowAllCalculationActive={allCalc}
              Applicability={fare?.Applicability}
              key={index!}
              isActivate={true}
              myRefs={props.myRefs!}
              IsFareBooking={false}
              BookFare={BookFare}
              ShowCalcDebugger={props.ShowCalcDebugger}
              setFetching={props.setFetching}
              setError={props.setError}
              setResponse={props.setResponse}
              setExtraAncCheck={props.setExtraAncCheck}
              showSelected={false}
              TotalFare={fares}
            />
          </ErrorBoundary>
        ))}
      </React.Fragment>
    );
  }

  const FilteredFares: JSX.Element = useMemo(
    () =>
      RenderFares(
        props.Fares,
        props.ShowAllLegsActive,
        props.ShowAllFlightTimesActive,
        props.ShowAllCalculationActive
      ),
    [
      props.Fares,
      props.ShowAllLegsActive,
      props.ShowAllFlightTimesActive,
      props.ShowAllCalculationActive,
    ]
  );

  //termsCondition redux
  const sessionInfo = useSelector((state: State) => state.Session);
  const showBackModalColor = JSON.parse(
    sessionInfo.FrontendSettings.b2cSettingsText
  );


  return (
    <React.Fragment>
      <div style={{ margin: "3px" }} className="fare__selector_container">
        {/* FARES */}
        {props?.Fares && (
          <div className="fare__selector">
            {FilteredFares}
            {props.TotalFaresAmount === 0 ? (
              <div
                id="fare__nofilteredresults"
                className="border border-dark rounded mx-2 mx-md-0 mb-4 p-2 text-center"
              >
                <FontAwesomeIcon icon={faEyeSlash} className="me-1" />{" "}
                <span className="font-weight-bold">
                  {t("FareList.FareError.NoFare")}
                </span>{" "}
                <FontAwesomeIcon icon={faEyeSlash} className="ms-1" />
                <br />
                {props.TotalFaresAmount === 0 && (
                  <p className="mt-2 mb-0 pb-0">
                    {t("FareList.FareError.NoResult")}
                  </p>
                )}
                {props.FaresWhichPassfilter === 0 &&
                  props.TotalFaresAmount > 0 && (
                    <p className="mt-2 mb-0 pb-0">
                      {t("FareList.FareError.ChangeFilter")}
                    </p>
                  )}
              </div>
            ) : null}
            {(props.TotalFaresAmount === 0 ||
              props.FaresWhichPassfilter === 0) &&
              props?.airlineCollector.length != 0 ? (
              <div
                id="fare__nofilteredresults"
                className="border border-dark rounded mx-2 mx-md-0 mb-4 p-2 text-center"
              >
                <FontAwesomeIcon icon={faEyeSlash} className="me-1" />{" "}
                <span className="font-weight-bold">
                  {t("FareList.FareError.NoFilterFare")}
                </span>{" "}
                <FontAwesomeIcon icon={faEyeSlash} className="ms-1" />
                <br />
                {props.TotalFaresAmount === 0 && (
                  <p className="mt-2 mb-0 pb-0">
                    {t("FareList.FareError.NoResult")}
                  </p>
                )}
                {props.FaresWhichPassfilter === 0 &&
                  props.TotalFaresAmount > 0 && (
                    <p className="mt-2 mb-0 pb-0">
                      {t("FareList.FareError.ChangeFilter")}
                    </p>
                  )}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Modal centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal} backdropClassName={showBackModalColor?.ShowBackgroundModalColor == "On" ? "loaderColorHalf" : "loaderColorZero"}>
        <ModalHeader toggle={ToggleExpiredModal}>
          {t("FareList.FareError.Expired")}
        </ModalHeader>
        <ModalBody>
          <Alert color="danger">
            <h4 className="alert-heading">
              {t("FareList.FareError.ResultExpired")}
            </h4>
            <p>{t("FareList.FareError.NewSearch")}</p>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button onClick={ToggleExpiredModal} color="primary">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export interface AvailableFaresFare {
  Fare: AvailableFareModel;
  Applicability: FareFilterApplicability;
}

export default AvailalableFaresFares;
