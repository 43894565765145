import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const _ = require("lodash");
interface CabinclassProps {
  readonly CabinClasses: string[];
  BrandName: any;
}
export const CabinClasses: React.FC<CabinclassProps> = (props) => {
  // Flatten the array and extract brand names
  const uniqueBrandNames = _.chain(props.BrandName)
    .flatMap("connections")
    .flatMap("segments")
    .map("brandName")
    .uniq()
    .value();

  const output = _.map(uniqueBrandNames, (brandName: any) =>
    _.toUpper(brandName)
  );

  function brandNameLanguage(name: string) {
    if (name == "LIGHT") {
    }
    if (name == "STANDARD") {
    }
    if (name == "FLEX") {
    }
    if (name == "ECONOMY LIGHT") {
    }
    if (name == "ECONOMY CLASSIC") {
    }
    if (name == "ECONOMY GREEN") {
    }
    if (name == "ECONOMY FLEX") {
    }
    if (name == "ECONOMY SAVER") {
    }
    if (name == "COMFORTFLEX") {
    }
    if (name == "BUSINESS") {
    }
    if (name == "BASIC") {
    }
    if (name == "DISCOUNT") {
    }
    if (name == "EUROP ESSENTIALS") {
    }
    if (name == "CLASSIC") {
    }
    if (name == "LITE") {
    }
  }

  //showBrandName redux 
  const sessionInfo = useSelector((state: any) => state.Session);
  const showBrandName = JSON.parse(
    sessionInfo?.FrontendSettings?.b2cSettingsText
  );

  return (
    <>
      {
        showBrandName?.showBrandName == "On" && <>
          {output.map((brandName: string) => {
            return (
              <span
                style={{
                  color: "#fff",
                  backgroundColor: "#eabd18",
                  padding: "0px 6px",
                  textAlign: "left",
                  marginBottom: "1px",
                  width: "110px",
                }}
              >
                {brandName}
              </span>
            );
          })}
        </>
      }

      <span className="">
        {props.CabinClasses.map((cabinclass, index) => {
          return (
            <CabinClassIcon
              CabinClass={cabinclass}
              key={"props.CabinClasses" + index}
            />
          );
        })}
      </span>
    </>
  );
};

interface CabinClasIconProps {
  CabinClass: string;
}
const CabinClassIcon: React.FC<CabinClasIconProps> = (props) => {
  const { t } = useTranslation();

  // switch (props.CabinClass) {
  //   case "economy":
  //     return (
  //       <span className="class_name class_name_y">
  //         {t("FareList.FareHeaderCabin.Economy")}
  //       </span>
  //     );
  //   case "premiumeconomy":
  //     return (
  //       <span className="class_name class_name_p">
  //         {t("FareList.FareHeaderCabin.Premium")}
  //       </span>
  //     );
  //   case "business":
  //     return (
  //       <span className="class_name class_name_c">
  //         {t("FareList.FareHeaderCabin.Business")}
  //       </span>
  //     );
  //   case "first":
  //     return (
  //       <span className="class_name class_name_f">
  //         {t("FareList.FareHeaderCabin.First")}
  //       </span>
  //     );
  //   case "economy, business":
  //     return (
  //       <React.Fragment>
  //         <span className="class_name class_name_y">
  //           {t("FareList.FareHeaderCabin.Economy")}
  //         </span>
  //         <span> | </span>
  //         <span className="class_name class_name_c">
  //           {t("FareList.FareHeaderCabin.Business")}
  //         </span>
  //       </React.Fragment>
  //     );
  //   default:
  //     return (
  //       <span className="class_name bg-white text-dark">
  //         {t("FareList.FareHeaderCabin.Unknown")}
  //       </span>
  //     );
  // }

  switch (props.CabinClass) {
    case "economy":
      return (
        <span className="class_name class_name_y">
          ECONOMY
        </span>
      );
    case "premiumeconomy":
      return (
        <span className="class_name class_name_p">
          PREMIUM
        </span>
      );
    case "business":
      return (
        <span className="class_name class_name_c">
          BUSINESS
        </span>
      );
    case "first":
      return (
        <span className="class_name class_name_f">
          FIRST
        </span>
      );
    case "economy, business":
      return (
        <React.Fragment><span className="class_name class_name_y mx-1">ECONOMY</span><span> | </span><span className="class_name class_name_c mx-1">BUSINESS</span></React.Fragment>
      );
    default:
      return (
        <span className="class_name bg-white text-dark">
          UNKNOWN
        </span>
      );
  }
};
