import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultIconData } from "components/General/types/iconData";
import {
  DefaultSessionState,
  SessionState,
  WaitScreenProgress,
} from "components/General/types/SessionTypes";
import { } from "Constants";
import { DeployInfo } from "hooks/GetAppInfo";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import {
  AgentFrontendSettings,
  b2cSettingsText,
  BaseApiResponseMetaData,
} from "WebApiClient";

const initialState: SessionState = { ...DefaultSessionState };

export const SessionSlice = createSlice({
  name: "counter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    Session_Reducer_SetDeployInfo: (
      state,
      action: PayloadAction<DeployInfo>
    ) => {
      state.DeployInfo = action.payload;
    },
    Session_Reload: (state, action) => {
      state.VersionDifferenceToBackend = action.payload;
    },
    Session_Reducer_Reset: (state) => {
      state = { ...DefaultSessionState };
    },
    Session_Reducer_SetToken: (state, action: PayloadAction<string>) => {
      const token = action.payload;
      const decodedToken: any = jwtDecode(action.payload);
      const webName: string = decodedToken.WebName;
      const backendAppversion: string = decodedToken.appversion;
      const deeplink: string = decodedToken.deeplink;
      const webID: string = decodedToken.WebId;
      const paymentDataSetting = decodedToken.paymentDataSetting;
      let versionDifferenceToBackend = false;
      // || process.env.NODE_ENV === 'development'
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        versionDifferenceToBackend = false;
      } else {
        versionDifferenceToBackend =
          backendAppversion !== state.DeployInfo?.Version;
      }

      if (versionDifferenceToBackend) {
        state.VersionDifferenceToBackend = true;
        state.NewBackendVersion = backendAppversion;
      }
      state.webID = webID;
      state.isLoggedIn = true;
      state.JwtToken = token;
      state.WebName = webName;
    },
    Session_Reducer_SetFrontendSettings: (
      state,
      action: PayloadAction<AgentFrontendSettings>
    ) => {
      // Check if iconDataSettings is empty and assign default value if true
      if (!action.payload.iconDataSettings || action.payload.iconDataSettings.length === 0) {
        action.payload.iconDataSettings = defaultIconData;
      }

      state.FrontendSettings = action.payload;
    },
    Session_Reducer_SetB2cSettings: (state: any, action: PayloadAction<string | undefined>) => {
      const defaultB2cSettings = "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Left\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":true,\"preSelect\":false}},\"showMarqueeFrom\":\"2024-05-20T11:54\",\"showMarqueeText\":\"\",\"showMarqueeTo\":\"2024-05-20T11:55\",\"priceOption\":\"pricePerPerson\",\"TermsConditionsLink\":\"\",\"ShowBackgroundModalColor\":\"Off\",\"showBrandName\":\"On\",\"showAssistance\":\"On\",\"ConfirmationText\":\"<p>Thank you for booking with Everest Travel.\\n#Booking Reference#\\nYou will receive a confirmation email soon.</p>\",\"Style\":\"\",\"ShowDefaultFilter\":\"On\"}";
      let dynamicB2cSettings = action.payload;
      let defaultSettings: b2cSettingsText = JSON.parse(defaultB2cSettings);
      let dynamicSettings: Partial<b2cSettingsText> = dynamicB2cSettings ? JSON.parse(dynamicB2cSettings) : {};
      const customizer = (objValue: any, srcValue: any) => {
        return _.isEmpty(srcValue) ? objValue : srcValue;
      };

      let b2cSettings: b2cSettingsText = _.mergeWith({}, defaultSettings, dynamicSettings, customizer);
      // Assuming state.b2cSettings is where you want to save the merged settings
      state.b2cSettings = b2cSettings;
    },
    Session_Reducer_ShowCustomerWaitScreen: (state) => {
      state.ShowCustomerWaitScreen = true;
    },
    Session_Reducer_PaymentDataSetting: (state) => {
      state.ShowCustomerWaitScreen = true;
    },
    Session_Reducer_isAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    Session_Reducer_HideCustomerWaitScreen: (state) => {
      state.ShowCustomerWaitScreen = false;
    },
    Session_Reducer_ShowGeneralWaitScreen: (state) => {
      state.ShowGeneralWaitScreen = true;
      state.WaitscreenProgress = { Percentage: 0, Label: "" };
    },
    Session_Reducer_HideGeneralWaitScreen: (state) => {
      state.ShowGeneralWaitScreen = false;
      state.WaitscreenProgress = { Percentage: 0, Label: "" };
    },
    Session_Reducer_SetWatiScreenInfo: (
      state,
      action: PayloadAction<WaitScreenProgress>
    ) => {
      state.WaitscreenProgress = action.payload;
    },
    Session_Reducer_PushTransaction: (
      state,
      action: PayloadAction<BaseApiResponseMetaData>
    ) => {
      state.Transactions.unshift(action.payload);
      state.Transactions.splice(10, 1); // save max 10 TIDS
    },
  },
});

export const {
  Session_Reducer_SetDeployInfo,
  Session_Reducer_Reset,
  Session_Reducer_SetToken,
  Session_Reducer_HideCustomerWaitScreen,
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_PushTransaction,
  Session_Reducer_SetFrontendSettings,
  Session_Reducer_SetB2cSettings,
  Session_Reducer_SetWatiScreenInfo,
  Session_Reducer_ShowCustomerWaitScreen,
  Session_Reducer_ShowGeneralWaitScreen,
  Session_Reducer_isAdmin,
  Session_Reload,

} = SessionSlice.actions;
