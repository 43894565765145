import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DefaultCabinClassState,
  ICabinClassState,
  IFareSearchInputState,
  ISegmentState,
} from "components/FareSearch/types/FareSearchTypes";
import * as moment from "moment";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
} from "reactstrap";
import CabinClassInput from "../Passengers/Passengers.CabinClasses";
import Segment from "./FlightDetails.Segment";
import { State } from "rootExports/rootReducer";
import { useTranslation } from "react-i18next";
//import icon
import Passengers from "../Passengers/Passengers";


//redux
import { useDispatch, useSelector } from "react-redux";
import { segment_Reducer } from "rootExports/SegmentReducer";
import { segmentBool_Reducer } from "rootExports/SegmentBoolReducer";
//mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { Snackbar, styled } from "@mui/material";
import { b2cSettingsText } from "WebApiClient";
interface FlightDetailsProps {
  IsModifySearch: boolean;
  Input: IFareSearchInputState;
  UpdateState: (state: IFareSearchInputState) => void;
  OnSubmit: () => void;
  isValidSearch: boolean;
  IsAvailableFares?: any;
  Configuration?: any;
  setInput?: any;
  Update?: any;
  State?: any;
  boxRef?: any;
  setScrollingDown?: any;
  language: string;
  scrollingDown?:boolean;
  isFareListHeader?:boolean;
  isB2cAirline:boolean;
}

//snackbar style
const StyledSnackbar = styled(Snackbar)({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "#f44336", // Red color for error
  },
});

const FlightDetails: React.FC<FlightDetailsProps> = (props) => {
  const { t } = useTranslation();
  const maxSegments = 4;
  const currSegments = props.Input.Segments.length;
  const BodyClass: string = props.IsModifySearch ? "" : " card-body-primary";
  const ContainerClass: string = props.IsModifySearch ? "" : "card";
  const cabinclasses = props.Input.CabinClasses;
  const inputRef = React.useRef<HTMLInputElement | any>();
  const [cabinContainer, setShowCabinContainer] = useState<boolean>(false);
  const searchRef = React.useRef<HTMLInputElement | any>();

  //Redux
  const SegmentReduxValue: any = useSelector((state: any) => state.Segment);
  const SegmentReduxBool: any = useSelector((state: any) => state.SegmentBool);

  const dispatch = useDispatch();

  //validation
  const [ValidationTrigger, setValidationTrigger] = useState(false);

  //cabin_show_hide
  const isAdmin = useSelector((state: State) => state.Session.isAdmin);

  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  let otherSetting = otherSettings.split("\n");
  let showCabin =
    otherSetting[8] == undefined ? "showCabinContainer=All" : otherSetting[8];

  const sessionInfo = useSelector((state: State) => state.Session);
  const showDateInputSide: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();

  //media query
  const matches = useMediaQuery("(max-width:1464px)");
  const [modalAdult, setModalAdult] = useState(false);

  const toggleAdult = () => setModalAdult(!modalAdult);

  const elemRef11 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showCabin == "showCabinContainer=All") {
      setShowCabinContainer(true);
    } else if (showCabin == "showCabinContainer=Admin" && isAdmin) {
      setShowCabinContainer(true);
    } else if (showCabin == "showCabinContainer=None") {
      setShowCabinContainer(false);
    }
  }, [showCabin]);

  //Effect to focus the departure of new segment when a new segment is added.
  useEffect(() => {
    if (currSegments > 1 && showDateInputSide.ShowDateInput == "Left") {
      document.getElementById(`segment${currSegments - 1}_date`)?.focus();
    } else {
      var input: any = document.getElementById(`segment${currSegments - 1}_date`);
      var input1: any = document.getElementById(`segment${currSegments - 1}_airport${currSegments - 1}`);
      if(input && input.value === '' && props.Input.Segments.length !== 1) {
        if (props.Input.Segments[currSegments - 1].ArrivalInput !== "" && props.Input.Segments[currSegments - 1].DepartureInput !== "") {
          input.focus();
        }
      } else {
        input1?.focus()
      }
    }
  }, [currSegments]);

  function UpdateCabinClasses(cabinClasses: ICabinClassState) {
    const newState: IFareSearchInputState = {
      ...props.Input,
      CabinClasses: cabinClasses,
    };
    props.UpdateState(newState);
  }

  const sendPageHeight = () => {
    const height = document.body.scrollHeight + 80;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  function OnAddSegment(): void {
    sendPageHeight();
    const newSegments: ISegmentState[] = AddSegment(props.Input.Segments);
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    console.log(props.Input.Segments.length, newState);

    props.UpdateState(newState);
    props.boxRef(props.Input.Segments.length + 2);
  }

  function OnAddReturnSegment(index: number): void {
    const newSegments: ISegmentState[] = AddReturnSegment(
      props.Input.Segments,
      index
    );
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  function OnToggleDirectFlights(): void {
    let newState: IFareSearchInputState = {
      ...props.Input,
      DirectFlightsOnly: !props.Input.DirectFlightsOnly,
    };
    props.UpdateState(newState);
  }

  function OnToggleAddBaggage(): void {
    let newState: IFareSearchInputState = {
      ...props.Input,
      AddBaggage: !props.Input.AddBaggage,
    };
    props.UpdateState(newState);
  }

  function OnToggleCabinClassPerLeg(): void {
    let newState: IFareSearchInputState = {
      ...props.Input,
      CabinClassPerLeg: !props.Input.CabinClassPerLeg,
    };
    props.UpdateState(newState);
  }

  function OnToggleAvailFares() {
    let newState: IFareSearchInputState = {
      ...props.Input,
      AvailableFares: !props.Input.AvailableFares,
    };
    props.UpdateState(newState);
  }

  function OnRemoveSegment(index: number): void {
    let newSegments = [...props.Input.Segments];
    newSegments.splice(index, 1);
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
    props.boxRef(props.Input.Segments.length - 1);
  }

  function OnUpdateSegment(index: number, segment: ISegmentState) {
    let newSegments = [...props.Input.Segments];
    newSegments[index] = segment;
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  const returnTabId = (segId: number, inputTab: number) => {
    switch (segId) {
      case 1:
        if (inputTab == 1) return 4;
        if (inputTab == 2) return 9;
        if (inputTab == 3) return 10;
        break;
      case 2:
        if (inputTab == 1) return 7;
        if (inputTab == 2) return 12;
        if (inputTab == 3) return 13;
        break;
      case 3:
        if (inputTab == 1) return 10;
        if (inputTab == 1) return 15;
        if (inputTab == 1) return 16;
        break;
      default:
        return;
    }
  };

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 13) {
      dispatch(segmentBool_Reducer(true));
      dispatch(segment_Reducer(props.Input));
      props.OnSubmit();
    }
  };

  useEffect(() => {
    if (SegmentReduxBool.value) {
      props.UpdateState(SegmentReduxValue.value);
      setValidationTrigger(true);
    }
  }, []);

  //date check undefined
  const hasEmptyOrUndefinedDateInput = _.some(props.Input.Segments, (obj) =>
    _.isUndefined(obj.DateInput)
  );
  //check current date is greater than prev date
  const isDateInputInOrder = _.every(
    _.tail(props.Input.Segments),
    (obj, index) => {
      const date1: any = props.Input.Segments[index]?.DateInput;
      const date2: any = obj?.DateInput;
      const prevDate = new Date(date1);
      const currentDate = new Date(date2);
      return currentDate >= prevDate;
    }
  );

  const sameDepartureAndArrival = _.every(
    props.Input.Segments,
    (segment) =>
      segment.DepartureInput === segment.ArrivalInput &&
      !(segment.DepartureInput == "" && segment.ArrivalInput == "")
  );

 function searchFunction() {
    if (!sameDepartureAndArrival) {
      dispatch(segment_Reducer(props.Input));
      dispatch(segmentBool_Reducer(true));
      props.OnSubmit();
    } 
  }

  useEffect(() => {
    props.boxRef(props?.Input?.Segments?.length);
  }, [props?.Input?.Segments?.length]);



  return (
    <React.Fragment>
      <Col>
        <div className={`flightSearchCardPosition`}>
          <div className={`${BodyClass}`}>
            <div className="row">
              {props.Input.Segments.map((segment: ISegmentState, index) => (
                <div className="col-12" key={`search_seg_${index}`}>
                  <Segment
                    Input={props.Input}
                    SegmentIndex={index}
                    Segment={segment}
                    fullSegment={props.Input.Segments}
                    NextSegment={props.Input.Segments[index + 1]}
                    PreviousSegment={props.Input.Segments[index - 1]}
                    RemoveSegment={OnRemoveSegment}
                    AddReturnSegment={OnAddReturnSegment}
                    UpdateSegment={OnUpdateSegment}
                    UpdateState={props.UpdateState}
                    OnSubmit={props.OnSubmit}
                    isValidSearch={props.isValidSearch}
                    cabinContainer={cabinContainer}
                    setValidationTrigger={setValidationTrigger}
                    ValidationTrigger={ValidationTrigger}
                    setScrollingDown={props.setScrollingDown}
                    isDateInputInOrder={isDateInputInOrder}
                    language={props.language}
                    scrollingDown={props.scrollingDown}
                    isFareListHeader={props.isFareListHeader}
                    isB2cAirline={props.isB2cAirline}
                  />
                </div>
              ))}
              {sameDepartureAndArrival && (
                <div style={{ color: "red" }}>Please check the Airports</div>
              )}
              {!isDateInputInOrder && (
                <div style={{ color: "red" }}> {t("error.dateError")}</div>
              )}
              {/* {(hasEmptyOrUndefinedDateInput || !isDateInputInOrder) && (
                <div style={{ color: "red" }}>Please check the Date</div>
              )} */}
              <div className="cabinOffContainer d-flex justify-content-between">
                <div
                  style={{
                    width: cabinContainer ? "100%" : "81%",
                  }}
                  className="col-12"
                  onKeyDown={OnKeyDown}
                >
                  <div className="d-grid">
                    <button
                      id="addFlight"
                      disabled={currSegments >= maxSegments}
                      onClick={(e) => {
                        e.stopPropagation();
                        OnAddSegment();
                      }}
                      className="btn btn-primary mt-2"
                      type="button"
                      ref={inputRef}
                      tabIndex={returnTabId(props.Input.Segments.length, 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {""}{" "}
                      <span className="d-inline-block">
                        {t("LandingPage.SearchForm.AddFlight")}
                      </span>
                    </button>
                  </div>
                </div>

                {!cabinContainer && (
                  <div
                    className="col-auto btn-group btn-group-vertical cabinBtnOffDiv"
                    onKeyDown={OnKeyDown}
                  >
                    <button
                      id="searchBtn"
                      className="btn btn-lg btn-primary text-white cabinBtnOn"
                      style={{ height: "100%" }}
                      ref={searchRef}
                      onClick={props.OnSubmit}
                      disabled={props.isValidSearch ? false : true}
                    >
                      <FontAwesomeIcon
                        beat={props.isValidSearch ? true : false}
                        icon={faSearch}
                      />{" "}
                      {t("LandingPage.SearchForm.Search")}
                    </button>
                  </div>
                )}
              </div>

              {cabinContainer && (
                <div className="col-12">
                  <div className="content-divider-dark" />
                </div>
              )}
              <div
                className="d-flex justify-content-between flex-wrap cabinFlexContainerParent"
                style={{
                  paddingRight: "15px",
                }}
              >
                <div className="d-flex align-items-center flex-wrap cabinFlexContainer">
                  {!props.Input.CabinClassPerLeg && cabinContainer && (
                    <div className="col-auto wCabin me-1">
                      <CabinClassInput
                        CabinClasses={cabinclasses}
                        UpdateCabinClasses={UpdateCabinClasses}
                        ClassName="form-row"
                        disabled={props.Input.CabinClassPerLeg}
                        segmentIndex={props.Input.Segments.length}
                        reduxCabinClass={SegmentReduxValue.value.CabinClasses}
                      />
                    </div>
                  )}

                  <div
                    className="d-flex justify-content-center align-items-center flex-wrap mt-1 mt-lg-0 passengerFlexMobile"
                    ref={elemRef11}
                  >
                    <div
                      onClick={toggleAdult}
                      className="Adult d-flex align-items-center cursor-pointer "
                      style={{
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Passengers
                        IsModifySearch={props.IsModifySearch}
                        Input={props.Input}
                        IsAvailableFares={props.IsAvailableFares}
                        UpdateState={props.UpdateState}
                        Configuration={props.Configuration}
                        setInput={props.setInput!}
                      />
                    </div>
                  </div>
                  <div className="d-flex D_B_Btn">
                    <div style={{ margin: "0 1px" }}>
                      <div className="">
                        <div
                          className=""
                          onClick={(e) => {
                            e.stopPropagation();
                            OnToggleDirectFlights();
                          }}
                        >
                          <input
                            type="checkbox"
                            className="btn-check"
                            checked={props.Input!.DirectFlightsOnly}
                            readOnly={true}
                          />
                          <label className="btn btn-outline-primary">
                            {t("LandingPage.SearchForm.DirectOnly")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "0 1px" }}>
                      <div className="">
                        <div
                          className=""
                          onClick={(e) => {
                            e.stopPropagation();
                            OnToggleAddBaggage();
                          }}
                        >
                          <input
                            type="checkbox"
                            className="btn-check"
                            checked={props.Input!.AddBaggage}
                            readOnly={true}
                          />
                          <label className="btn btn-outline-primary">
                            {t("LandingPage.SearchForm.Baggage")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {cabinContainer && matches && (
                    <div
                      className="btn-group btn-group-vertical mt-3 mt-lg-0 cabinBtnOnDiv"
                      onKeyDown={OnKeyDown}
                    >
                      <button
                        id="searchBtn"
                        className="btn btn-lg btn-primary text-white cabinBtnOn"
                        ref={searchRef}
                        onClick={searchFunction}
                        disabled={
                          !props.isValidSearch ||
                            sameDepartureAndArrival ||
                            hasEmptyOrUndefinedDateInput ||
                            !isDateInputInOrder
                            ? true
                            : false
                        }
                      >
                        <FontAwesomeIcon
                          beat={props.isValidSearch ? true : false}
                          icon={faSearch}
                        />{" "}
                        {t("LandingPage.SearchForm.Search")}
                      </button>
                    </div>
                  )}
                </div>

                {cabinContainer && !matches && (
                  <div
                    className="btn-group btn-group-vertical mt-3 mt-lg-0 cabinBtnOnDiv"
                    onKeyDown={OnKeyDown}
                  >
                    <button
                      id="searchBtn"
                      className="btn btn-lg btn-primary text-white cabinBtnOn"
                      ref={searchRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        searchFunction();
                      }}
                      disabled={
                        !props.isValidSearch ||
                          sameDepartureAndArrival ||
                          hasEmptyOrUndefinedDateInput ||
                          !isDateInputInOrder
                          ? true
                          : false
                      }
                    >
                      <FontAwesomeIcon
                        beat={props.isValidSearch ? true : false}
                        icon={faSearch}
                      />{" "}
                      {t("LandingPage.SearchForm.Search")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default FlightDetails;

function AddSegment(segments: ISegmentState[]): ISegmentState[] {
  const latestSegment = segments[segments.length - 1];
  // const newDate = moment(latestSegment.DateInput).add(2, "weeks").toDate();
  const newDate = undefined;

  const newSegment: ISegmentState = {
    DepartureInput: latestSegment.ArrivalInput,
    ArrivalInput: latestSegment.DepartureInput,
    Radius: 0,
    DateInput: newDate,
    CabinClasses: DefaultCabinClassState,
  };
  let newSegments = [...segments];
  newSegments.push(newSegment);
  return newSegments;
}

function AddReturnSegment(
  segments: ISegmentState[],
  index: number
): ISegmentState[] {
  const segment: ISegmentState = segments[index];
  const returnDate = moment(segment.DateInput).add(2, "weeks").toDate();
  const returnSegment: ISegmentState = {
    DepartureInput: segment.ArrivalInput,
    ArrivalInput: segment.DepartureInput,
    Radius: 0,
    DateInput: returnDate,
    CabinClasses: DefaultCabinClassState,
  };
  let newSegments = [...segments];
  newSegments.push(returnSegment);
  return newSegments;
}
