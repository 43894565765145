import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FrquentFlyperInput } from "components/FareSearch/component/Passengers/Passengers.AvailFaresPassengers.Modal";
import { DateDisplay } from "components/Shared/DateDisplay";
import DateInput from "components/Shared/DateInput";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import {
  AgentGenderEnum,
  BonusProgramModel,
  FareBookingInputPassengerModel,
} from "WebApiClient";
import { BookPassengerValidationGeneralTabResult } from "./FareBooking.Validate.Passenger.General";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import PhoneInputWithCountrySelect from "react-phone-number-input";
export interface IBookingPassengerGeneralTabProps {
  Passenger: FareBookingInputPassengerModel;
  DobLocked: boolean;
  OnUpdatePassenger: (data: FareBookingInputPassengerModel) => void;
  Index: number;
  Valid: BookPassengerValidationGeneralTabResult;
  IsOpen: boolean;
  Toggle: () => void;
  Next: () => void;
  setIsConfirmEmail: any
  isAdultPresent?: any
  departureDate?: any
  dontContact: boolean
  setPaymentBOFDetails: any;
  reviewedChecked:boolean;
}
export interface passengerConfig {
  PassengerDOB: string;
  PassengerGender: string;
}
interface ValidationTrigger {
  Gender: boolean;
  Title: boolean;
  FirstName: boolean;
  LastName: boolean;
  DateOfBirth: boolean;
  Email: boolean;
  Mobile: boolean;
}
const BookingPassengerGeneralTab: React.FC<IBookingPassengerGeneralTabProps> = (
  props
) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
    showFareHeader=Off
    showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerDOB":"No","PassengerGender":"Unselected"}`
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  let settingArr = otherSettings.split("\n");
  var passengerConfig: passengerConfig =
    settingArr[5] == undefined
      ? { PassengerDOB: "No", PassengerGender: "Unselected" }
      : JSON.parse(settingArr[5]);
  const passenger = props.Passenger;
  const IsValid = props.Valid;
  const { t } = useTranslation();
  const [ConEmail, setConEmail] = useState('')
  function OnTitleUpdate(value: string) {
    let data = { ...passenger };
    // data.gender = GetGenderFromTitle(value);
    data.title = value;
    props.OnUpdatePassenger(data);
    updateTrigger("Title",true)
  }
  function OnFirstNameUpdate(value: string) {
    let data = { ...passenger };
    data.firstName = value;
    props.OnUpdatePassenger(data);
    updateTrigger("FirstName",true)
  }
  function OnMiddleNameUpdate(value: string) {
    let data = { ...passenger };
    data.middleName = value;
    props.OnUpdatePassenger(data);
    updateTrigger("Gender",true)
  }
  function OnLastNameUpdate(value: string) {
    let data = { ...passenger };
    data.lastName = value;
    props.OnUpdatePassenger(data);
    updateTrigger("LastName",true)
  }
  function OnGenderUpdate(value: string) {
    let data = { ...passenger };
    data.gender = parseInt(value);
    props.OnUpdatePassenger(data);
    updateTrigger("Gender",true)
  }
  function OnDateOfBirthUpdate(value: Date | undefined) {
    updateTrigger("DateOfBirth",true)
    let data = { ...passenger };
    data.dateOfBirth = value;
    props.OnUpdatePassenger(data);
  }
  function OnAddFrequentFlyer() {
    let data = { ...passenger };
    let ffC = data.frequentFlyerCards ? [...data.frequentFlyerCards] : [];
    ffC.push({
      carrierCodes: [],
      extensions: undefined,
      holderFirstName: undefined,
      holderLastName: undefined,
      holderTitle: undefined,
      programNumber: "",
      programType: undefined,
    });

    props.OnUpdatePassenger({ ...data, frequentFlyerCards: ffC });
  }
  function OnEditFrequentFlyer(
    index: number,
    val: BonusProgramModel | undefined
  ) {
    let data = { ...passenger };
    if (data.frequentFlyerCards) {
      let ffC = [...data.frequentFlyerCards];
      if (val) {
        ffC[index] = val;
      } else {
        ffC.splice(index, 1);
      }
      props.OnUpdatePassenger({ ...data, frequentFlyerCards: ffC });
    }
  }
  function OnEmailUpdate(value: string) {
    let data = { ...passenger };
    data.email = value;
    props.OnUpdatePassenger(data);
    updateTrigger("Email",true);
  }
  function OnConfEmailUpdate(value: string) {
    if (passenger.email == ConEmail) {
      props.setIsConfirmEmail(true)
    }
    if (passenger.email != ConEmail) {
      props.setIsConfirmEmail(false)
    }
    setConEmail(value)

    // props.setPaymentBOFDetails((prevInputValues: any) => ({
    //     ...prevInputValues,
    //     CTCE: value,
    //   }));
  }
  function OnMobileUpdate(value: string | any) {
    let data = { ...passenger };
    data.mobile = value;
    props.OnUpdatePassenger(data);
    // props.setPaymentBOFDetails((prevInputValues: any) => ({
    //     ...prevInputValues,
    //     CTCM: value,
    //   }));
    updateTrigger("Mobile",true);
  }
  // Define the dates
  const date1 = moment(passenger.dateOfBirth);
  const date2 = moment(props.departureDate, 'DD.MM.YYYY');
  // Calculate the difference in years
  const differenceInMonths = date2.diff(date1, 'months');
  const checkValidChild = differenceInMonths > 168 || differenceInMonths == 168;

  //Show/Hide passenger title  
  const sessionInfoB2c = useSelector((state: State) => state.Session);
  const b2cSettingsText = JSON.parse(sessionInfoB2c?.FrontendSettings?.b2cSettingsText);
  const showPassengerTitle = b2cSettingsText?.showPassengerTitleName !== undefined ? b2cSettingsText?.showPassengerTitleName : "on";
  
  //validation trigger initialization
  const [validationTrigger, setValidationTrigger] = useState<ValidationTrigger>({
    Gender: false,
    Title: false,
    FirstName: false,
    LastName: false,
    DateOfBirth: false,
    Email: false,
    Mobile: false,
  });

  const updateTrigger = (triggerName:any, value:boolean) => {
    setValidationTrigger(prevState => ({
      ...prevState,
      [triggerName]: value,
    }));
  };

useEffect(() => {
  if(props.reviewedChecked){
    setValidationTrigger({
      Gender: true,
      Title: true,
      FirstName: true,
      LastName: true,
      DateOfBirth: true,
      Email: true,
      Mobile: true,
    })
  }
}, [props.reviewedChecked])

  return (
    <div className="row generalPContainer">
      <div className="col-md-6 leftPDetails text-nowrap">
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3 ">
              {t("FareBooking.PassengerPage.Gender")}
            </label>
            <div className="col-md-8 col-lg-9">
              <div className="">
                <button
                  type="button"
                  className={`btn ${passenger.gender === AgentGenderEnum.Male
                    ? "btn-primary"
                    : "btn-outline-primary"
                    } ${validationTrigger.Gender ? IsValid.Gender ? "" : "btn_invalid" : null}`}
                  onClick={() => OnGenderUpdate("1")}
                >
                  {t("FareBooking.PassengerPage.Male")}
                </button>
                &nbsp;
                <button
                  type="button"
                  className={`btn ${passenger.gender === AgentGenderEnum.Female
                    ? "btn-primary"
                    : "btn-outline-primary"
                    }
                                        ${validationTrigger.Gender ? IsValid.Gender ? "" : "btn_invalid" : null}`}
                  onClick={() => OnGenderUpdate("2")}
                >
                  {t("FareBooking.PassengerPage.Female")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          showPassengerTitle == 'On' && <div className="col-md-12 mb-2">
            <div className="row align-items-center">
              <label className="col-md-4 col-lg-3 align-middle">
                {t("FareBooking.PassengerPage.Title")}
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  value={passenger.title ? passenger.title : ""}
                  onChange={(e) => OnTitleUpdate(e.target.value)}
                  type="text"
                  className={`form-control ${validationTrigger.Title ? IsValid.Title ? "" : "is-invalid" : null}`}
                  placeholder={t("FareBooking.PassengerPage.Title")}
                />
              </div>
            </div>
          </div>
        }
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3  align-middle">
              {t("FareBooking.PassengerPage.FirstName")}
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                value={passenger.firstName ? passenger.firstName : ""}
                onChange={(e) => OnFirstNameUpdate(e.target.value)}
                type="text"
                className={`form-control ${validationTrigger.FirstName ? IsValid.FirstName  ? "is-valid" : "is-invalid"
                  : null}`}
                placeholder={t("FareBooking.PassengerPage.FirstNamePlaceHolder")}
                name="first Name"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-2 text-wrap">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3  align-middle">
              {t("FareBooking.PassengerPage.MiddleName")}
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                value={passenger.middleName ? passenger.middleName : ""}
                onChange={(e) => OnMiddleNameUpdate(e.target.value)}
                type="text"
                className={`form-control`}
                placeholder={t("FareBooking.PassengerPage.MiddleNamePlaceHolder")}
                name="middle Name"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3  align-middle">
              {t("FareBooking.PassengerPage.LastName")}
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                value={passenger.lastName ? passenger.lastName : ""}
                onChange={(e) => OnLastNameUpdate(e.target.value)}
                type="text"
                className={`form-control ${validationTrigger.LastName ? IsValid.LastName ? "is-valid" : "is-invalid"
                  : null}`}
                placeholder={t("FareBooking.PassengerPage.LastNamePlaceHolder")}
                name="last Name"
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3 ">
              {t("FareBooking.PassengerPage.Dob")}
            </label>
            <div className="col-md-8 col-lg-9">
              {props.DobLocked && (
                <DateDisplay date={passenger.dateOfBirth}></DateDisplay>
              )}
              {!props.DobLocked && (
                <DateInput
                  OnDateChange={(date: Date | undefined) => OnDateOfBirthUpdate(date)}
                  Value={passenger.dateOfBirth}
                  InputGroup={false}
                  IsDob={true}
                  MaxDate={moment().toDate()}
                  MinDate={moment().subtract(120, "years").toDate()}
                  InputClassNames={`form-control ${passengerConfig.PassengerDOB === "No"
                    ? null
                    : validationTrigger.DateOfBirth ? IsValid.DateOfBirth 
                      ? "is-valid"
                      : "is-invalid":null
                    }`}
                    isDateInputInOrder={true}
                />
              )} 
              {
                (!checkValidChild && !props.isAdultPresent && passenger.dateOfBirth != undefined) &&
                <span style={{ color: 'red', fontSize: '12px' }}>{t("FareBooking.PassengerPage.ChildErrorAge")}</span>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 rightPDetails text-nowrap">
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3 align-middle">
              {t("FareBooking.PassengerPage.Email")}
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                value={passenger.email ? passenger.email : ""}
                onChange={(e) => OnEmailUpdate(e.target.value)}
                type="text"
                className={`form-control ${validationTrigger.Email ? IsValid.Email  ? "is-valid" : "is-invalid" : null}`}
                placeholder={t("FareBooking.PassengerPage.Email")}
                name="email"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3 align-middle">
              {t("FareBooking.PassengerPage.ConfirmEmail")}
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                value={ConEmail}
                onChange={(e) => OnConfEmailUpdate(e.target.value)}
                type="text"
                className={`form-control ${validationTrigger.Email ? passenger.email == ConEmail  ? "is-valid" : "is-invalid" : null}`}
                placeholder={t("FareBooking.PassengerPage.ConfirmEmail")}
                name="email"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div className="row align-items-center">
            <label className="col-md-4 col-lg-3 align-middle">
              {t("FareBooking.PassengerPage.Mobile")}{" "}
            </label>
            <div className="col-md-8 col-lg-9">
              <PhoneInputWithCountrySelect
                placeholder={t("FareBooking.PassengerPage.MobilePlaceholder")}
                value={passenger.mobile || ""}
                className={`form-control ${validationTrigger.Mobile ? IsValid.Mobile  ? "is-valid" : "is-invalid" : null}`}
                countrySelectProps={{ className: "custom-country-select" }}
                onChange={(value) => OnMobileUpdate(value)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div className="row">
            {passenger.frequentFlyerCards &&
              passenger.frequentFlyerCards.map((ff, i) => (
                <div key={"frequentFlyerCards" + i} className="col-12 mb-2">
                  <FrquentFlyperInput
                    OnUpdate={(e) => {
                      OnEditFrequentFlyer(i, e);
                    }}
                    data={ff}
                    index={i}
                  />
                </div>
              ))}
            <div className="col-12 mt-2">
              <button
                className="btn btn-secondary"
                onClick={OnAddFrequentFlyer}
              >
                <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '4px' }} />
                {t("FareBooking.PassengerPage.FrequentFlyer")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPassengerGeneralTab;
