import { useSelector } from 'react-redux';
import { State } from 'rootExports/rootReducer'; // Ensure you have the correct path to your root reducer
import image25Kg from '../../../Styles/images/IncludedServIcons/25K.png';
import image02P from '../../../Styles/images/IncludedServIcons/02P.png';
import baggage from '../../../Styles/images/IncludedServIcons/image.gif';
import bagGrey from '../../../Styles/images/IncludedServIcons/bag.gif';
import BREAKFAST from '../../../Styles/images/IncludedServIcons/Layer 10.png';
import DINNER from '../../../Styles/images/IncludedServIcons/Layer 3.png';
import SNACK from '../../../Styles/images/IncludedServIcons/Layer 2.png';
import LUNCH from '../../../Styles/images/IncludedServIcons/Layer 4.png';
import SANDWICH from '../../../Styles/images/IncludedServIcons/Layer 5.png';
import BEER from '../../../Styles/images/IncludedServIcons/Layer 6.png';
import DRINK from '../../../Styles/images/IncludedServIcons/Layer 7.png';
import POPCORN from '../../../Styles/images/IncludedServIcons/Layer 8.png';
import SOUP from '../../../Styles/images/IncludedServIcons/Layer 9.png';
import LUGGAGE from '../../../Styles/images/IncludedServIcons/Layer 11.png';
import BAGGAGENEW from '../../../Styles/images/IncludedServIcons/Layer 12.png';
import CHAIR from '../../../Styles/images/IncludedServIcons/Layer 13.png';
import MILES from '../../../Styles/images/IncludedServIcons/Layer 14.png';
import { IconData } from 'WebApiClient';
// Define the structure of your icon data



interface IconEntry {
    icon: string; // Could be a string (for emojis) or an import (for image paths)
    hint: string; // Description or hint associated with the icon
}

// Define the main interface for the SabreIconsEmoji object
interface SabreIconsEmoji {
    [key: string]: IconEntry; // Keys can be any string representing the icon code
}

export const SabreIconsEmoji = (): Record<string, IconEntry> => {
    // Use selector to get the icon data from the Redux store
    const iconData: IconData[] = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);    
    // Transform the icon data into a usable format
    const icons = iconData.reduce((acc, { code, hint, defaultIcon, newIcon }) => {
        
        acc[code] = { icon: newIcon?.length || newIcon != null ? newIcon  : defaultIcon, hint }; // Construct the icon entry
        return acc; // Return the accumulator for the next iteration
    }, {} as Record<string, IconEntry>); // Initialize the accumulator
    
    // Add the static values
    const staticIcons = {
        "01P": { icon: BAGGAGENEW, hint: "" },
        "02P": { icon: image02P, hint: "" },
        "25K": { icon: image25Kg, hint: "" }
    };
    
    // Merge dynamic icons with static ones
    const allIcons = { ...icons, ...staticIcons };
    
    // console.log("icons--------------------", allIcons);

    return allIcons; // Return the combined icons object
};
