import normalizeName from "components/Shared/Helpers/NormalizeName";
import React, { useState, ChangeEvent, FocusEvent, useEffect } from "react";
import Cards, { Focused } from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useTranslation } from "react-i18next";

interface PaymentState {
  paymentFormState: any;
  setPaymentFormState: any;
  setPaymentValidation?: any;
  firstPassengerName?: any;
  reviewedChecked:boolean
}

const PaymentForm: React.FC<PaymentState> = ({
  paymentFormState,
  setPaymentFormState,
  setPaymentValidation,
  firstPassengerName,
  reviewedChecked
}) => {
  const { t } = useTranslation();

  const [validationErrors, setValidationErrors] = useState<any>({
    name: "Invalid cardholder name",
    number: "Invalid card number",
    expiry: "Invalid CVV",
    cvc: "Invalid expiry date",
  });

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    setPaymentFormState({
      ...paymentFormState,
      focus: e.target.name as Focused,
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let normalizedValue = value;
    updateTrigger(name, true);

    if (name === "name") {
      normalizedValue = normalizeName(value);
    }
    let isValid = true;
    let errorMessage = "";
    switch (name) {
      case "number":
        isValid = /^\d{16}$/.test(normalizedValue);
        errorMessage = "Invalid card number";
        break;
      case "name":
        isValid = /^[a-zA-Z\s]+$/.test(normalizedValue);
        errorMessage = "Invalid cardholder name";
        break;
      case "expiry":
        isValid = /^\d{2}\/\d{2}$/.test(normalizedValue);
        errorMessage = "Invalid expiry date";
        break;
      case "cvc":
        isValid = /^\d{3}$/.test(normalizedValue);
        errorMessage = "Invalid CVV";
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: isValid ? "" : errorMessage,
    }));

    setPaymentFormState((prevState: any) => ({
      ...prevState,
      [name]: normalizedValue,
    }));
    // Check if all fields are valid
    const allFieldsValid = Object.values(validationErrors).every(
      (value) => value === ""
    );
    setPaymentValidation(allFieldsValid);
  };

  useEffect(() => {
    // Check if all fields are valid
    const allFieldsValid = Object.values(validationErrors).every(
      (value) => value === ""
    );
    setPaymentValidation(allFieldsValid);
  }, [
    validationErrors?.name.length,
    validationErrors?.number.length,
    validationErrors?.cvc.length,
    validationErrors?.expiry.length,
  ]);

  useEffect(() => {
    let isValid = true;
    let errorMessage = "";

    let CardHolderName = "";
    const firstName = firstPassengerName[0]?.firstName || "";
    const middleName = firstPassengerName[0]?.middleName || "";
    const lastName = firstPassengerName[0]?.lastName || "";
    if (middleName?.length > 0) {
      CardHolderName = `${firstName} ${middleName} ${lastName}`.trim();
    }
    if (middleName?.length == 0) {
      CardHolderName = `${firstName} ${lastName}`.trim();
    }
    console.log("CardHolderName", CardHolderName);

    // Validate cardholder name


    // Update validation errors state
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      name: isValid ? "" : errorMessage,
    }));

    const NormalizeName = normalizeName(CardHolderName)
    // Update payment form state with cardholder name
    setPaymentFormState((prevState: any) => ({
      ...prevState,
      name: NormalizeName,
    }));

    if (!/^[a-zA-Z\s]+$/.test(NormalizeName)) {
      isValid = false;
      errorMessage = "Invalid cardholder name";
    }

    // Check if all fields are valid
    const allFieldsValid = Object.values(validationErrors).every((value) => value === "");
    setPaymentValidation(allFieldsValid);

  }, [
    firstPassengerName[0]?.firstName,
    firstPassengerName[0]?.middleName,
    firstPassengerName[0]?.lastName,
  ]);

  useEffect(() => {
    if (paymentFormState?.name == '') {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        name: t("FareBooking.PaymentSection.InvalidName"),
      }));
    }
  }, [paymentFormState?.name])

  const [validationTriggers, setValidationTriggers] = useState({
    name: false,
    number: false,
    expiry: false,
    cvc: false,
  });
  
  // To update a specific trigger:
  const updateTrigger = (triggerName:string, value:boolean) => {
    setValidationTriggers(prevState => ({
      ...prevState,
      [triggerName]: value,
    }));
  };

  useEffect(() => {
    if(reviewedChecked){
      setValidationTriggers({
        name: true,
        number: true,
        expiry: true,
        cvc: true,})
    }
  }, [reviewedChecked])
  
  
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center">
      <div className="credit-card-container col-12 col-sm-12 col-md-6 col-lg-6 mt-4">
        <Cards
          cvc={paymentFormState.cvc}
          expiry={paymentFormState.expiry}
          focused={paymentFormState.focus}
          name={paymentFormState.name}
          number={paymentFormState.number}
        />
      </div>
      <form className="payment-form  col-12 col-sm-12 col-md-10 col-lg-6">
        <input
          type="text"
          name="name"
          placeholder={t("FareBooking.PaymentSection.CardholderName")}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          value={paymentFormState.name}
          required
          className={
            validationTriggers.name? validationErrors.name != "" && paymentFormState.name.length < 2
              ? "error"
              : "" : ""
          }
        />
        {((validationErrors.name !== "" || paymentFormState.name == "")&&( validationTriggers.name)) && (
          <div className="error-message">
            {t("FareBooking.PaymentSection.InvalidName")}
          </div>
        )}
        <input
          type="tel"
          name="number"
          placeholder={t("FareBooking.PaymentSection.CardNumber")}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required
          className={ validationTriggers.number?  validationErrors.number ? "error" : "" : ""}
        />
        {(validationErrors.number )&&( validationTriggers.number) && (
          <div className="error-message">
            {t("FareBooking.PaymentSection.InvalidNumber")}
          </div>
        )}
        <input
          type="tel"
          name="expiry"
          placeholder="MM/YY Expiry"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required
          className={ validationTriggers.expiry ? validationErrors.expiry ? "error" : "" : ""}
        />
        {(validationErrors.expiry )&&( validationTriggers.expiry) && (
          <div className="error-message">
            {t("FareBooking.PaymentSection.InvalidDate")}
          </div>
        )}
        <input
          type="tel"
          name="cvc"
          placeholder="CVV"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required
          className={validationTriggers.cvc ? validationErrors.cvc ? "error" : "" : ""}
        />
        {(validationErrors.cvc )&&( validationTriggers.cvc) && (
          <div className="error-message">
            {t("FareBooking.PaymentSection.InvalidCVV")}
          </div>
        )}
      </form>
    </div>
  );
};

export default PaymentForm;