import {
  faPlaneArrival,
  faPlaneDeparture,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidSegmentAirport } from "components/FareSearch/Business/ValidateSearch";
import {
  AirportType,
  dropdownItem,
  ISegmentState,
} from "components/FareSearch/types/FareSearchTypes";
import { AgentApiURL } from "Constants";
import React, { useEffect, useState } from "react";
import { ArrowContainer, Popover, PopoverState } from "react-tiny-popover";
import { Card, ListGroup, ListGroupItem } from "reactstrap";
import { ApiCredentialsOptions, PublicServicesClient, b2cSettingsText } from "WebApiClient";
import nextElementFocus from "./nextElementFocus";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface OwnProps {
  SegmentIndex: number;
  Segment: ISegmentState;
  AirportType: AirportType;
  UpdateSegment: (index: number, segment: ISegmentState) => void;
  OnSelectNextElem(type: AirportType): void;
  departureCollector?: any;
  setdepartureCollector?: any;
  arrivalCollector?: any;
  isInValidMonth: boolean;
  reference: any;
  tabIndex: number;
  setValidationTrigger?: any;
  ValidationTrigger?: any;
  setScrollingDown?: any;
  language: string;
  isFareListHeader?: boolean;
  isB2cAirline: boolean;
}

const AirportInput: React.FC<OwnProps> = (props) => {
  const debug = false;
  const segment = props.Segment;
  const FrontendSettings = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);
  const B2cSettring = JSON.parse(FrontendSettings);
  const RouteFilter = B2cSettring?.RouteFilter;
  const isDeparture: boolean = props.AirportType === AirportType.Departure;
  const PrependIcon: JSX.Element = isDeparture ? (
    <FontAwesomeIcon icon={faPlaneDeparture} />
  ) : (
    <FontAwesomeIcon icon={faPlaneArrival} />
  );
  const IconTitle: string = isDeparture ? "Outbound" : "Inbound";
  const InputID: string = `segment${props.SegmentIndex}_airport${props.AirportType}`;
  const isInValidMonth = props.isInValidMonth;
  let InputValue = isDeparture ? segment.DepartureInput : segment.ArrivalInput;
  const [InputActive, setInputActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<dropdownItem[]>([]);
  const [defaultOriginState, setDefaultOriginState] = useState<dropdownItem[] | undefined>([]);
  const [defaultDestinationState, setDefaultDestinationState] = useState<dropdownItem[] | undefined>([]);
  const [departureAirports, setDepartureAirports] = useState<string[]>([]);
  const [arrivalAirports, setArrivalAirports] = useState<string[]>([]);
  const [activeAutoCompleteItem, setActiveAutoCompleteItem] = useState(0);
  let departureAirportValue = "";
  const [depAirportValueDisplay, setDepAirportValueDisplay] = useState(false);
  const sessionInfo = useSelector((state: State) => state.Session);
  var isNativeSearch: boolean = false;
  //individual validation state, turn off initially trigger only change
  const [validationTriggerInner, setValidationTriggerInner] = useState(false)
  //Language Translation
  const { t } = useTranslation();
  function GetInputValidLabel(): string {
    const isValid = ValidSegmentAirport(InputValue);
    if (isValid) return "is-valid";
    return "is-invalid";
  }
  function GetInputValidMonth(): string {
    const isInValid = isInValidMonth;
    if (isInValid) return "is-invalid";
    return "is-valid";
  }
  const showDateInputSide: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();
  // is-invalid
  function stringSeparator(val: any) {
    isNativeSearch = true;
    let str = `${val}`;
    let depString: string;
    let arrString: string;
    let dayString: string;
    let monthString: string;
    if (showDateInputSide?.ShowDateInput === "Right") {
      depString = str.slice(0, 3);
      arrString = str.slice(3, 6);
      dayString = str.slice(6, 8);
      monthString = str.slice(8, 10);
    } else {
      depString = str.slice(4, 7);
      arrString = str.slice(7, 10);
      dayString = str.slice(0, 2);
      monthString = str.slice(2, 4);
    }
    //for 1205framad
    if (depString.length > 2 && typeof depString === "string") {
      props.setdepartureCollector((prev: any) => ({
        ...prev,
        departureL: depString,
      }));
    }
    if (arrString.length > 2 && typeof arrString === "string") {
      props.setdepartureCollector((prev: any) => ({
        ...prev,
        arrivalL: arrString,
      }));
    }
    if (dayString) {
      props.setdepartureCollector((prev: any) => ({
        ...prev,
        DayL: Number(dayString),
      }));
    }
    if (monthString) {
      props.setdepartureCollector((prev: any) => ({
        ...prev,
        MonthL: Number(monthString),
      }));
    }
  }
  const b2cSettingsText = sessionInfo?.FrontendSettings?.b2cSettingsText;
  let settingsObject;
  try {
    settingsObject = JSON.parse(
      b2cSettingsText || '{"HotelTab":"All","AirPortSearchType":"All"}'
    );
  } catch (error) {
    settingsObject = { HotelTab: "All", AirPortSearchType: "All" };
  }
  const checkAirportType: boolean = settingsObject?.AirPortSearchType == "All" ? false : true;

  const getDefaultAirport = async () => {
    const routes: string[] = RouteFilter ? RouteFilter.split(",").map((route: any) => route.trim()) : [];
    const departureAirportsSet = new Set<string>();
    const arrivalAirportsSet = new Set<string>();
    routes.forEach((route: string) => {
      const [departure, arrival] = route.split("-");
      departureAirportsSet.add(departure);
      arrivalAirportsSet.add(arrival);
    });
    setDepartureAirports(Array.from(departureAirportsSet));
    setArrivalAirports(Array.from(arrivalAirportsSet));
    const client: PublicServicesClient = new PublicServicesClient(
      new ApiCredentialsOptions(AgentApiURL, "")
    );
    if (props.isB2cAirline) {
      const departure1: [] | any = await getFullAirlinesRespectively(Array.from(departureAirportsSet), client, true)
      const arrival1: [] | any = await getFullAirlinesRespectively(Array.from(arrivalAirportsSet), client, false)
      setDefaultDestinationState(arrival1);
      setDefaultOriginState(departure1)
      if (isDeparture) {
        sendPageHeight(departure1?.length);
      } else {
        sendPageHeight(arrival1?.length);
      }
    }
  };

  async function getFullAirlinesRespectively(root: any, client: any, checkDeparture: boolean) {
    if (root?.length) {
      let items: any[] = [];
      try {
        for (const val of root) {
          const result = await client.getAirportsByPrefix(val, props.language, checkAirportType);
          if (result.length > 0) {
            const map: dropdownItem[] = result
              .filter((e: any) => val === e.iata && e.name !== "Alle Flughäfen" && e.name !== "All airports")
              .map((e: any) => ({
                value: e.iata!,
                text: `[${e.iata}] - ${e.cityName} - ${e.name}`,
                image: e.countryCode!
              }));

            if (!isNativeSearch) {
              items.push(...map);
            }
            if (!props?.isFareListHeader) {
              // sendPageHeight(map.length);
            }
          } else {
            items = [];
            setItems([]);
          }
        }
      } catch (error) {
        setItems([]);
      } finally {
        setIsLoading(false);
        if (checkDeparture) {
          setItems(items);
        }
        return items
      }
    }
  }
  function OnSearch(e: any) {
    e.stopPropagation();
    setValidationTriggerInner(true)
    const val = e.target.value
    var isTrue: boolean = false;
    setInputActive(true);
    // props.setValidationTrigger(true);
    departureAirportValue = val;
    if (departureAirportValue.length > 6) {
      setDepAirportValueDisplay(true);
    }
    if (departureAirportValue.length < 6) {
      setDepAirportValueDisplay(false);
    }
    //framad1205
    if (showDateInputSide?.ShowDateInput == "Right") {
      if (/^(?=.*\d.*\d.*\d.*\d)[A-Za-z\d]{6,}$/.test(val)) {
        stringSeparator(val);
      }
    } else {
      if (/^\d{4}[A-Za-z]{6}$/.test(val)) {
        stringSeparator(val);
      }
    }
    if (isDeparture) {
      const segment: ISegmentState = { ...props.Segment, DepartureInput: val };
      props.UpdateSegment(props.SegmentIndex, segment);
      const airports = departureAirports.map(airport => airport.toLowerCase());
      isTrue = airports.includes(val);
    } else {
      const segment: ISegmentState = { ...props.Segment, ArrivalInput: val };
      props.UpdateSegment(props.SegmentIndex, segment);
      const airports = arrivalAirports.map(airport => airport.toLowerCase());
      isTrue = airports.includes(val);
    }

    if (val.length > 2) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );
      if (props.isB2cAirline) {
        if (isTrue) {
          client.getAirportsByPrefix(val, props.language, checkAirportType).then((result: any) => {
            if (result.length > 0) {
              const map: dropdownItem[] = result
                .filter((e: any) => val === e.iata.toLowerCase() && e.name !== "Alle Flughäfen" && e.name !== "All airports").map((e: any) => ({
                  value: e.iata!,
                  text: `[${e.iata}] - ${e.cityName} - ${e.name}`,
                  image: e.countryCode!
                }));

              if (!isNativeSearch) {
                if (isDeparture) {
                  setItems(map);
                } else {
                  console.log(segment.DepartureInput);
                  const match = segment.DepartureInput.match(/\[(.*?)\]/);
                  const extractedVal = match ? match[1].toLowerCase() : '';
                  const arr = RouteFilter.toLowerCase().split(', ').filter((entry: any) => entry.startsWith(extractedVal)).map((entry: any) => entry.split('-')[1]).filter((entry: any) => entry === val);
                  console.log(map);
                  const filteredResults = map.filter((airport: dropdownItem) => arr.includes(airport.value.toLowerCase()));
                  console.log(arr, filteredResults, RouteFilter);
                  setItems(filteredResults);
                }
              }
              if (!props?.isFareListHeader) {
                // sendPageHeight(map.length);
              }
            } else {
              setItems([]);
            }
          }).catch(() => {
            setItems([]);
          })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          setInputActive(false);
        }
      } else {
        client.getAirportsByPrefix(val, props.language, checkAirportType).then((result) => {
          if (result.length > 0) {
            const map: dropdownItem[] = [];
            result.forEach((e) => {
              const text = `[${e.iata}] - ${e.cityName} - ${e.name}`;
              map.push({ value: e.iata!, text: text, image: e.countryCode! });
            });
            if (!isNativeSearch) {
              setItems(map);
            }
            if (!props?.isFareListHeader) {
              sendPageHeight(map.length);
            }
          } else {
            setItems([]);
          }
        }).catch(() => {
          setItems([]);
        })
          .finally(() => {
            setIsLoading(false);
          });
      }

    }
    // sendPageHeightDefault();
  };
  const sendPageHeight = (item: number) => {
    setTimeout(() => {
      // const height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", 46 * (item == 1 ? 2 : item) + 250 + "px"], "*");
    }, 0);
  };
  const sendPageHeightDefault = () => {
    setTimeout(() => {
      const element = document.querySelector('.avialFarePageContainer');
      if (element) {
        const height = element.scrollHeight + 150;
        window.parent.postMessage(["setHeight", height + "px"], "*");
      }
    }, 0);
  };
  const AirportAutoCompleteOptions = items.map((airport, index) => (
    <ListGroupItem className={`btn-sm`}
      key={`ac_${props.AirportType}_${props.SegmentIndex}_${index}`}
      onMouseEnter={() => setActiveAutoCompleteItem(index)}
      active={activeAutoCompleteItem === index}
      onClick={(e) => {
        e.stopPropagation();
        OnAutocompleteClick(e);
      }}
      tag="button"
      action
    >
      <img
        src={`/images/flags/${airport.image.toLowerCase()}.png`}
        alt="airport"
      />
      {` `}
      <span>{airport.text}</span>
      {/* {activeAutoCompleteItem === index &&
                <span className="small float-end">{"  "}<kbd>enter</kbd>,<kbd>tab</kbd> </span>
            } */}
    </ListGroupItem>
  ));
  function OnAutocompleteClick(e: any) {
    OnSelectAutocompleteItem();
  }
  function AirportLabel() {
    if (isLoading) {
      return "loading...";
    }
    if (items.length > 0) {
      return `${items.length.toString()} ${t("LandingPage.SearchForm.airport_found")}`;
    }
  }
  function ToolTipContent(popoverState: PopoverState): JSX.Element {
    return (
      <>
        {(isLoading || items.length > 0) && (
          <ArrowContainer
            position={popoverState.position}
            childRect={popoverState.childRect}
            popoverRect={popoverState.popoverRect}
            arrowColor="black"
            arrowSize={5}
          >
            <Card className={depAirportValueDisplay ? "hideAirportList" : ""}>
              <span className="text-center ">{AirportLabel()}</span>
              {/* <CardBody className="small"> */}
              {items.length > 0 && (
                <ListGroup flush={true}>{AirportAutoCompleteOptions}</ListGroup>
              )}
              {/* </CardBody> */}
            </Card>
          </ArrowContainer>
        )}
      </>
    );
  }
  // Extract the origin or destination code from the InputValue
  const codeRegex = /\[(.*?)\]/;
  // Extract the origin or destination code from the InputValue (safely)
  const extractedCode = isDeparture ? segment?.ArrivalInput ? (segment?.ArrivalInput.match(codeRegex)?.[1] || '') : '' : segment?.DepartureInput ? (segment?.DepartureInput.match(codeRegex)?.[1] || '') : '';
  // const routesExtracted: string[] = RouteFilter ? RouteFilter.split(", ").map((route: any) => route.trim()) : [];
  const routesExtracted: string[] = RouteFilter ? RouteFilter.split(/,\s*/).map((route: any) => route.trim()) : [];

  // Find the matching routes based on isDeparture
  const relevantRoutes = routesExtracted.filter(route => {
    const [origin, destination] = route.split('-');
    return !isDeparture ? origin === extractedCode : destination === extractedCode;
  });
  // Get the relevant airport codes from the matching routes
  const relevantCodes = relevantRoutes.map(route => {
    return !isDeparture ? route.split('-')[1] : route.split('-')[0];
  });
  function OnFocus(e: React.FocusEvent<HTMLInputElement>, isDeparture: boolean) {

    e.stopPropagation();
    if (routesExtracted?.length > 0) {
      // Filter the defaultOriginState to include only the relevant codes
      const filteredAirportsOrigin = defaultOriginState?.filter(airport => {
        return relevantCodes.includes(airport.value);
      });

      const filteredAirportsDestination = defaultDestinationState?.filter(airport => {
        return relevantCodes.includes(airport.value);
      });
      if (InputValue?.length == 0 && isDeparture) {
        if (relevantCodes?.length == 0) {
          setItems(defaultOriginState!)
        } else {
          setItems(filteredAirportsOrigin!)
        }
      }

      if (InputValue?.length == 0 && !isDeparture) {
        if (relevantCodes?.length == 0) {
          setItems(defaultDestinationState!)
        } else {
          setItems(filteredAirportsDestination!)
        }
      }
    }

    if (debug) {
      console.log(`Focus: ${isDeparture ? "Departure" : "Arrival"}`);
    }
    if (items?.length > 0) {
      if (!props?.isFareListHeader) {
        sendPageHeight(items?.length)
      }
    }

    setInputActive(true);
    e.target.select();
  }

  function OnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const key = event.which;
    if (key === 40 || key === 38 || key === 13 || key === 9) {
      if (!(key === 13 && event.ctrlKey)) {
        // event.preventDefault();
        event.stopPropagation();
        if (key === 40) {
          //down arrow
          if (activeAutoCompleteItem < items.length - 1) {
            setActiveAutoCompleteItem(activeAutoCompleteItem + 1);
          }
        }
        if (key === 38) {
          //up arrow
          if (activeAutoCompleteItem > 0) {
            setActiveAutoCompleteItem(activeAutoCompleteItem - 1);
          }
        }
        if (!event.shiftKey && event.keyCode) {
          if (key === 13 || key === 9) {
            // tab/enter
            OnSelectAutocompleteItem();
          }
        }
      }
    }
  }
  function OnPopoverClickOutside() {
    if (debug) {
      console.log(
        `OnPopoverClickOutside: ${isDeparture ? "Departure" : "Arrival"}`
      );
    }
    setInputActive(false);
    sendPageHeightDefault();
  }
  function OnSelectAutocompleteItem() {
    if (debug) {
      console.log(
        `OnSelectAutocompleteItem: ${isDeparture ? "Departure" : "Arrival"}`
      );
    }

    if (items[activeAutoCompleteItem] !== undefined) {
      const autoCompleteItem = items[activeAutoCompleteItem];
      const value = autoCompleteItem.text;
      OnValueChanged(value);
    }
    setInputActive(false);
    setTimeout(function () {
      // set timeout to avoid triggering PopoverClick outside event on next input element
      props.OnSelectNextElem(props.AirportType);
    }, 100);
  }
  function OnValueChanged(value: string) {
    if (debug) {
      console.log(`OnValueChanged: ${isDeparture ? "Departure" : "Arrival"}`);
    }

    if (isDeparture) {
      const segment: ISegmentState = {
        ...props.Segment,
        DepartureInput: value,
      };
      props.UpdateSegment(props.SegmentIndex, segment);
    } else {
      const segment: ISegmentState = { ...props.Segment, ArrivalInput: value };
      props.UpdateSegment(props.SegmentIndex, segment);
    }
  }
  const reversibleSearchCollapse = (e: any) => {
    e.stopPropagation();
    props.setScrollingDown(false);
  };
  const OffClose = () => {
    setTimeout(function () {
      setInputActive(false)
    }, 300);

    sendPageHeightDefault();
    if (InputValue?.length != 0 && GetInputValidLabel()) {
      OnSelectAutocompleteItem();
    }
  }
  const [isOutside1, setIsOutside1] = useState(false);
  const [isOutside2, setIsOutside2] = useState(false);

  const handleClickOutside1 = (event: any) => {
    const element1 = document.getElementById('clickableBox1');
    if (element1 && !element1.contains(event.target)) {
      setIsOutside1(true);
    } else {
      setIsOutside1(false);
    }
  };
  const handleClickOutside2 = (event: any) => {
    const element2 = document.getElementById('clickableBox2');
    if (element2 && !element2.contains(event.target)) {
      setIsOutside2(true);
    } else {
      setIsOutside2(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    window.addEventListener('mousedown', handleClickOutside1);
    window.addEventListener('mousedown', handleClickOutside2);
    getDefaultAirport();
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('mousedown', handleClickOutside1);
      window.removeEventListener('mousedown', handleClickOutside2);
    };
  }, []);
  return (
    <React.Fragment>
      <Popover
        onClickOutside={OnPopoverClickOutside}
        positions={["bottom", "right", "left", "top"]}
        isOpen={InputActive}
        containerStyle={{ zIndex: "100" }}
        content={(popoverState: PopoverState) => ToolTipContent(popoverState)}>
        <div className="input-group" id={isDeparture ? "clickableBox1" : "clickableBox2"}>
          <span className="input-group-text" title={`${IconTitle} Airport`}>
            {PrependIcon}
          </span>
          <input
            id={InputID}
            placeholder={isDeparture ? t("LandingPage.SearchForm.DepartureAirport") : t("LandingPage.SearchForm.DestinationAirport")}
            className={`form-control ${(validationTriggerInner || InputValue?.length > 0) ? GetInputValidLabel() : null} ${(validationTriggerInner || InputValue?.length > 0) ? GetInputValidMonth() : null}}`}
            tabIndex={props.tabIndex}
            value={InputValue}
            onBlur={(e) => { OffClose(); }}
            onClick={(e) => reversibleSearchCollapse(e)}
            onChange={(e) => { OnSearch(e) }}
            onFocus={(e) => OnFocus(e, isDeparture)}
            autoComplete={"off"}
            onKeyDown={(event) => {
              OnKeyDown(event);
              nextElementFocus({
                tabIndex: props.tabIndex,
                reference: { airlineRef: props.reference },
              });
            }}
            ref={props.reference}
          />
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default AirportInput;