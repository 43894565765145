import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PassengerJson from "./passenger.json";
import BookingPassengerOtherTab from "./FareBooking.Passengers.Passenger.Other";
import BookingPassengerFoidTab from "./FareBooking.Passengers.Passenger.Foid";
import BookingPassengerApisTab from "./FareBooking.Passengers.Passenger.Apis";
import BookingPassengerGeneralTab from "./FareBooking.Passengers.Passenger.General";
import {
  FareAPISRequirements,
  FareBookingInputPassengerModel,
  FareBookingPassengerApis,
  FareBookingPassengerView,
  FarebookingFormOfIdentifikationModel,
} from "WebApiClient";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import {
  IconDefinition,
  faArrowDown,
  faBaby,
  faChevronDown,
  faChevronUp,
  faChild,
  faMale,
} from "@fortawesome/pro-regular-svg-icons";
import { State } from "rootExports/rootReducer";
import { BookPassengerValidationResult } from "./FareBooking.Validate.Passenger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentDataTab from "./FareBooking.Passengers.Passenger.Payment";
// Import icon definitions and components...

interface IBookingPassengerProps {
  Apisrequirements: FareAPISRequirements;
  Passenger: FareBookingPassengerView;
  OnUpdatePassenger: (index: number, data: FareBookingPassengerView) => void;
  PassengersAmount: number;
  Index: number;
  Valid: BookPassengerValidationResult;
  IsOpen: boolean;
  Toggle: (index: number) => void;
  HasNext: boolean;
  ExpandNext: (index: number) => void;
  setPaymentBOFDetails: any;
  paymentBOFDetails: any;
  setPaymentDataBackend: any;
  paymentValid: any;
  setIsConfirmEmail?: any;
  isAdultPresent?: any;
  departureDate?: any;
  dontContact: boolean;
  reviewedChecked:boolean
}

interface passengerConfig {
  PassengerDOB: string;
  PassengerGender: string;
}

export const BookingPassenger: React.FC<IBookingPassengerProps> = ({
  Passenger,
  OnUpdatePassenger,
  Index,
  Valid,
  Toggle,
  HasNext,
  ExpandNext,
  paymentValid,
  setIsConfirmEmail,
  isAdultPresent,
  departureDate,
  dontContact,
  PassengersAmount,
  paymentBOFDetails,
  setPaymentBOFDetails,
  setPaymentDataBackend,
  IsOpen,
  reviewedChecked
}) => {
  const otherSettings: string = useSelector((state: State) => {
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  var passengerConfig: passengerConfig =
    settingArr[5] == undefined
      ? { PassengerDOB: "No", PassengerGender: "Unselected" }
      : JSON.parse(settingArr[5]);

  const passengerType: { [key: string]: string } = PassengerJson;
  const passenger: FareBookingPassengerView = Passenger;
  const passengerTypeChanged =
    passenger.resultPassengerAbbrevation !==
    passenger.originalPassengerAbbrevation;
  const { t } = useTranslation();
  const IsValid = Valid;

  function GetPassengerIcon(): IconDefinition {
    let result: IconDefinition = faMale;
    if (Valid.General.IsChild) result = faChild;
    if (Valid.General.IsInfant) result = faBaby;
    return result;
  }

  const sendPageHeight = () => {
    setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", height + "px"], "*");
    }, 500);
  };

  function ToggleBody() {
    Toggle(Index);
    ToggleCollapse();
    sendPageHeight1();
    sendPageHeight();
  }

  const sendPageHeight1 = () => {
    const height = 800;
    console.log("payment_", height);
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  const [allTabsOpen, setAllTabsOpen] = useState(true);
  const [generaTabOpen, setGeneralTabOpen] = useState(true);
  const [foidTabOpen, setFoidTabOpen] = useState(false);
  const [apisTabOpen, setApisTabOpen] = useState(false);
  const [OtherTabOpen, setOtherTabOpen] = useState(false);
  const [PaymentTabOpen, setPaymentTabOpen] = useState(true);

  //collapse
  const [collapseOpen, setCollapseOpen] = useState(true);

  function ToggleCollapse() {
    setCollapseOpen(!collapseOpen);
  }

  const Apis = { address: undefined, passport: undefined, visa: undefined };

  function ToggleAllPassengerTabs() {
    const inverted = !allTabsOpen;
    setGeneralTabOpen(inverted);
    setFoidTabOpen(inverted);
    setApisTabOpen(inverted);
    setOtherTabOpen(inverted);
    setAllTabsOpen(inverted);
    setPaymentTabOpen(inverted);
  }

  function OnUpdateApis(apis: FareBookingPassengerApis | undefined) {
    let data: FareBookingInputPassengerModel = { ...passenger.bookingData };
    data.apis = apis;
    OnUpdateData(data);
  }

  function OnUpdateFoid(
    apis: FarebookingFormOfIdentifikationModel | undefined
  ) {
    let data: FareBookingInputPassengerModel = { ...passenger.bookingData };
    data.formOfIdentification = apis;
    OnUpdateData(data);
  }

  function OnUpdateData(bookingData: FareBookingInputPassengerModel) {
    let data = { ...passenger };
    data.bookingData = bookingData;
    OnUpdatePassenger(Index, data);
  }

  useEffect(() => {
    let data = { ...passenger };
    if (passengerConfig.PassengerGender === "Male") {
      data.bookingData.gender = 1; // Male
    } else if (passengerConfig.PassengerGender === "Female") {
      data.bookingData.gender = 2; // Female
    } else {
      data.bookingData.gender = 0; // Unknown or other default value
    }
    OnUpdatePassenger(Index, data);
  }, []);

  useEffect(() => {
    passenger.bookingData.apis = Apis;
  }, [passenger.bookingData.apis != null]);

  return (
    <div>
      {/* <PaymentDataTab
        setPaymentBOFDetails={setPaymentBOFDetails}
        IsOpen={PaymentTabOpen}
        Toggle={() => setPaymentTabOpen(!PaymentTabOpen)}
        paymentBOFDetails={paymentBOFDetails}
        setPaymentDataBackend={setPaymentDataBackend}
        paymentValid={paymentValid}
      /> */}
      <div className="card mb-3">
        <div
          style={{ backgroundColor: "#2196F3", color: "white" }}
          className="card-header card-header-primary  px-3 py-2 d-flex clickme"
          onClick={ToggleBody}
        >
          <h6 style={{ lineHeight: "inherit" }} className="w-100">
            <span className="float-start">
              <ValidIndicatorIcon
                IsValid={IsValid.Valid && paymentValid}
                Icon={GetPassengerIcon()}
                NoValueSet={false}
              />
              <DisplayBookingPassengerName
                Passenger={passenger}
                ShowIcon={false}
                passengerTypeChanged={passengerTypeChanged}
              />
              {/* {passengerTypeChanged && (
                <span className="ml-2">
                  <span>
                    {passengerType[passenger.bookingData.passengerType]}
                  </span>
                </span>
              )} */}

              {passenger.bookingData.isMainPassenger && (
                <span className="ml-2">
                  &nbsp;({t("FareBooking.PassengerPage.MPassenger")})
                </span>
              )}
            </span>
          </h6>
          <div>
            <FontAwesomeIcon
              icon={collapseOpen ? faChevronUp : faChevronDown}
            />
          </div>
        </div>
        <Collapse
          isOpen={collapseOpen}
          className="border-2 border-top border-primary"
        >
          <div className="card-body paxData px-3">
            <BookingPassengerGeneralTab
              Passenger={passenger.bookingData}
              DobLocked={passenger.dateOfBirthLocked}
              OnUpdatePassenger={OnUpdateData}
              Index={Index}
              Valid={Valid.General}
              IsOpen={generaTabOpen}
              Toggle={() => setGeneralTabOpen(!generaTabOpen)}
              Next={() => {
                setGeneralTabOpen(false);
                setApisTabOpen(true);
              }}
              setIsConfirmEmail={setIsConfirmEmail}
              isAdultPresent={isAdultPresent}
              departureDate={departureDate}
              dontContact={dontContact}
              reviewedChecked={reviewedChecked}
              setPaymentBOFDetails={setPaymentBOFDetails}
            />

            <BookingPassengerOtherTab
              Passenger={passenger.bookingData}
              OnUpdatePassenger={OnUpdateData}
              Index={Index}
              Valid={Valid.Other}
              IsOpen={OtherTabOpen}
              Toggle={() => setOtherTabOpen(!OtherTabOpen)}
              Next={() => {
                setOtherTabOpen(false);
                if (HasNext) {
                  ExpandNext(Index);
                }
              }}
            />
            {/* <BookingPassengerApisTab
            Data={passenger.bookingData.apis}
            OnUpdate={OnUpdateApis}
            Valid={Valid.Passport}
            Apis={Apisrequirements}
            IsOpen={apisTabOpen}
            Toggle={() => setApisTabOpen(!apisTabOpen)}
            Next={() => {
              setApisTabOpen(false);
              setOtherTabOpen(true);
            }}
            ShowRemoveButton={true}
            passengerIndex={passenger.passengerTypeIndex + 1}
          /> */}
            {/* <BookingPassengerFoidTab
            Data={passenger.bookingData.formOfIdentification}
            OnUpdate={OnUpdateFoid}
            Valid={Valid.Foid}
            IsOpen={foidTabOpen}
            Apis={Apisrequirements}
            Toggle={() => setFoidTabOpen(!foidTabOpen)}
            Next={() => {
                setFoidTabOpen(false);
                setApisTabOpen(true);
            }}
            /> */}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

interface DisplayBookingPassengerNameProps {
  Passenger: FareBookingPassengerView;
  ShowIcon: boolean;
  passengerTypeChanged: boolean;
}

export const DisplayBookingPassengerName: React.FC<
  DisplayBookingPassengerNameProps
> = ({ Passenger, ShowIcon, passengerTypeChanged }) => {
  const passengerType: { [key: string]: string } = PassengerJson;

  function GetPassengerDisplayName(): string {
    const passengerTypeKey = Passenger.bookingData.passengerType;
    const isTypeChanged = passengerTypeChanged;
    let result: string;
    if (isTypeChanged && passengerTypeKey in passengerType) {
      result = `${passengerType[passengerTypeKey]}${Passenger.passengerTypeIndex + 1
        }`;
    } else {
      result = `${Passenger.resultPassengerDisplayName}${Passenger.passengerTypeIndex + 1
        }`;
    }
    return result;
  }
  return <span>{GetPassengerDisplayName()}</span>;
};
